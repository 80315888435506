  import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
  import { Optional,Component, PLATFORM_ID, Inject,Injectable,ViewChild, afterNextRender,Injector } from '@angular/core';
  import { HttpClient,HttpHeaders,HttpResponse } from '@angular/common/http';
  import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
  import { Webinar, HomeWebinar  } from '../models/webinar';
  import * as myGlobals from '../globals';
  import { CookieService } from 'ngx-cookie-service';
  import { BehaviorSubject } from 'rxjs';

  @Injectable({
    providedIn: 'root'
  })

  export class WebinarapiService  {

    private apiURL: string = myGlobals.apiURL;
    private subject = new Subject<any>();
    private cookieValue: string ='';
    private platformLocation;
    private domainDataSet: BehaviorSubject<number> = new BehaviorSubject(null);


    
    constructor(private http: HttpClient,
      private cookieService: CookieService,
      platformLocation: PlatformLocation,
      @Inject(PLATFORM_ID) private platformId: Object,
      @Inject(DOCUMENT) private document: Document,

      //@Optional() @Inject(HOST) private host: InjectionToken<string>,
      ) {
        afterNextRender(() => {
          // Safe to check `scrollHeight` because this will only run in the browser, not the server.
          
        });
        this.cookieValue = this.cookieService.get('webinar_ses');
      }

    //getLocation(): Location {
      //return this.document.location.path;
    //}
    getDomainCartData(): Observable<number> {
        this.getLatestDomainCartData();
        return this.domainDataSet.asObservable();
    }

    setDomainCartData(dataset: number) {
        this.domainDataSet.next(dataset);
    }

    getLatestDomainCartData() {
      this.getDomainDetails()
      .subscribe( data =>
        {
          if(data['0'].result.cartcount > 0)
          {
            this.domainDataSet.next(data['0'].result.cartcount);
          }
          else{
            this.domainDataSet.next(0);
          }
        
      });
    }
    
    

    sendMessage(message: string) {
        this.subject.next({ text: message });
    }

    clearMessages() {
        this.subject.next(true);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

      getHomeWebinarList(): Observable<HomeWebinar>
      { 
        return this.http.get<HomeWebinar>
          (this.apiURL+'webinar?page=upcoming&limit=12',{ headers:  this.getHeaders() });
      }

      getHomeVideoList(): Observable<HomeWebinar>
      {
        return this.http.get<HomeWebinar>
          (this.apiURL+'webinar?page=recorded&limit=12',{ headers:  this.getHeaders()});
      }

      getHomeSeminarList(): Observable<HomeWebinar>
      {
        return this.http.get<HomeWebinar>
          (this.apiURL+'webinar?page=seminar&limit=12',{ headers:  this.getHeaders()});
      }

      getWebinarList(): Observable<Webinar>
      {
        return this.http.get<Webinar>(this.apiURL+'webinar?page=upcoming',{ headers:  this.getHeaders()});
      }

      getVideoList(): Observable<HomeWebinar>
      {
        return this.http.get<HomeWebinar>(this.apiURL+'webinar?page=recorded',{ headers:  this.getHeaders()});
      }

      getSeminarList(): Observable<Webinar>
      {
        return this.http.get<Webinar>
        (this.apiURL+'webinar?page=seminar',{ headers:  this.getHeaders()});
      }

      getIndustriesList(): Observable<Webinar>
      {
        return this.http.get<Webinar>
        (this.apiURL+'industries',{ headers:  this.getHeaders()});
      }
      
      getWebinarDetails(id): Observable<Webinar>
      {
        return this.http.get<Webinar>
        (this.apiURL+'webinarDetails?id='+id,{ headers:  this.getHeaders()});
      }

      getSeminarDetails(id): Observable<Webinar>
      {
        return this.http.get<Webinar>(this.apiURL+'seminarDetails?json=1&id='+id,
        { headers:  this.getHeaders()}); 
      }

      getCart(id=null): Observable<Webinar>
      {
        return this.http.get<Webinar>(this.apiURL+'cart?id='+id,
        { headers:  this.getHeaders()}); 
      }

      deleteCart(cartId,id): Observable<Webinar>
      {
        var dataset = {'cartid':cartId,'id':id};
        return this.http.post<Webinar>(this.apiURL+'deletecart',dataset,
        { headers:  this.getHeaders()}); 
      }

      postSubscription(dataset): Observable<Webinar>
      {
        return this.http.post<Webinar>(this.apiURL+'subscription',dataset,
        { headers:  this.getHeaders()}); 
      }

      getDomainDetails(cookieId = ''): Observable<Webinar>
      {
        if (isPlatformBrowser(this.platformId)) {
          if(cookieId !=''){
            this.cookieValue = cookieId;
          }
          else if(this.cookieValue == undefined) {
            this.cookieValue = '';
          }
          this.setDomainCartData(0);
          return this.http.get<Webinar>
          (this.apiURL+'domain?host='+this.document.location.host+'&cart='+this.cookieValue,
          { headers:  this.getHeaders()});
        }
      }

      getOrderDetails(id): Observable<Webinar>
      {
        return this.http.get<Webinar>
        (this.apiURL+'orderDetails?id='+id,{ headers:  this.postHeaders()});
      }

      /**
       * Setting Header for API call
       */
      getHeaders()
      {
        let headers: HttpHeaders =new HttpHeaders({
          'Authorization': 'Bearer EFKUGQ5guZ5!Tq%',
          'Content-Type': 'application/json'
        });

        return headers;
      }

      /**
       * Setting Header for API call
       */
      postHeaders()
      {
        let headers: HttpHeaders =new HttpHeaders({
          'Authorization': 'Bearer EFKUGQ5guZ5!Tq%',
          'Content-Type': 'application/json'
        });
        //headers.append('x-api-key', 'EFKUGQ5guZ5!Tq%');
        //headers.append('Content-Type'  , 'application/x-www-form-urlencoded');
        //headers.append('Access-Control-Allow-Origin'  , '*');
        //headers = headers.append('Content-Type',  'application/json');
        
        return headers;
      }

      cartOrder(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'cart',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postOrder(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'order',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postPayment(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'stripepayment',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postDirectInvoice(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'stripeinvoice',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postPaypalPayment(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'paypalpayment',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postFailPaypalPayment(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'failpaypalpayment',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      

      postContact(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'contactus',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postKeySearch(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'keysearch',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      postLogin(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'login',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      getExploreList(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'explore',
          dataset
          ,
          { headers:  this.postHeaders()});
      }

      getAllIndustries(dataset): Observable<Webinar[]>
      {
        return this.http.post<Webinar[]>(
          this.apiURL+'industries',
          dataset
          ,
          { headers:  this.postHeaders()});
      }


     


      
      
  }
