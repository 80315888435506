<app-header></app-header>
<app-banner></app-banner>
<!-- Contact -->
<div id="contact" class="section">

  <!-- container -->
  <div class="container">

    <!-- row -->
    <div class="row">



      <div id="courses" class="section" *ngIf="homeIndustriesListData">

        <!-- container -->
        <div class="container">
      
          <!-- row -->
          <div class="row">
            <div class="section-header text-center">
              <h2>Industries</h2>
              <!-- <p class="lead">Libris vivendo eloquentiam ex ius, nec id splendide abhorreant.</p> -->
            </div>
          </div>
          <!-- /row -->
      
          <!-- courses -->
          <div id="courses-wrapper">
      
            
            <!-- row -->
            <div class="row">
      
              <!-- single course -->
              <div class="col-md-3 col-sm-6 col-xs-6" *ngFor="let singleItem of homeIndustriesListData.result">
                <div class="course">
                  <a [routerLink]="['/industries/', singleItem.slug]" class="course-img" title="{{ singleItem.category_name }}">
                    <img src="{{ singleItem.image_link }}" alt="{{ singleItem.category_name }}">
                    <i class="course-link-icon fa fa-link"></i>
                  </a>
                  <a class="course-title" [routerLink]="['/industries/', singleItem.slug]" title="{{ singleItem.category_name }}">{{ singleItem.category_name?.length>70 ? (singleItem.category_name | slice:0:70)+'..' : (singleItem.category_name) }}</a>
                  <!--
                  <div class="course-details">
                    <span class="course-category">{{ singleItem.category_name }}</span>
                    <span class="course-price course-free">{{ singleItem.price | currency }}</span>
                  </div>
                  -->
                </div>
              </div>
              <!-- /single course -->
      
              
      
            </div>
            <!-- /row -->
            
      
          </div>
          <!-- /courses -->
      
          <div class="row">
            <div class="center-btn">
              <a class="main-button icon-button" routerLink="/video-on-demand">View All</a>
            </div>
          </div>
      
        </div>
        <!-- container -->
      
      </div>

    </div>
    <!-- /row -->

  </div>
  <!-- /container -->

</div>
<!-- /Contact -->

<app-footer></app-footer>

