<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Terms Of Service</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>Cancellation/Rescheduling/Refund Terms
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                    <div class="privacy-policy-content" *ngIf="domainListData">
                         
                        <div class="privacy-policy-content">
                            <p>We value your choice of {{domainListData.title}} for online training. Please review our terms and condition:</p>
                            
                            <h5>For Live/On-Demand Webinars:</h5>
                            <ol>
                               <li>Notify us 48 hours before the event to cancel for a credit note.</li>
                               <li>Failure to cancel means no refund, but access to the on-demand version.</li>
                               <li>{{domainListData.title}} can cancel or reschedule due to specific reasons.</li>
                               <li>Refunds for cancellations are processed within 10 working days.</li>
                               <li>{{domainListData.title}} is not responsible for delivery issues or technical problems.</li>
                               <li>Content satisfaction is the responsibility of the speaker; please review descriptions before purchase.</li>
                            </ol>
                         </div>
                        <h3>For further information, please send your queries from our <a routerLink="/contact">Contact Us</a> page.</h3>
                        
                     </div>

                </div>



            </div>

        </div>
        
    </div>
</div>

<app-footer></app-footer>
