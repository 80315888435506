import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { Title }     from '@angular/platform-browser';
import { Webinar,HomeWebinar } from 'src/app/models/webinar';
import {CookieService} from 'ngx-cookie-service';
import { Subscription,Observable, Subject } from 'rxjs';
import { ReactiveFormsModule,FormBuilder,FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [CookieService],
})
export class HeaderComponent implements OnInit {
  //public domainListData = [];
  domainListData: any[] = [];
  subscription: Subscription;
  public cookieValue:string;
  public searchKey: string;
  public loading = true;
  public searchForm;
  public domainCartcount:number;

  constructor(private cgapiService: WebinarapiService,
    private titleService: Title,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private router:Router,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object, ) {
     
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cookieValue = this.cookieService.get('webinar_ses');
      console.log(this.cookieValue)
      // this.cookieService.get('Test');
      this.cgapiService.getDomainDetails(this.cookieValue)
        .subscribe( data =>
          {
            console.log(data['0']);

            if(data['0'].result.title != undefined)
            {
                this.titleService.setTitle( data['0'].result.title );
                this.domainListData['title'] = data['0'].result.title;
            }

            if(data['0'].result.cartcount > 0)
            {
              this.domainListData['cartcount'] = data['0'].result.cartcount;
            }
            else
            {
              this.domainListData['cartcount'] = 0;
            }
            this.cgapiService.setDomainCartData(this.domainListData['cartcount']);
        });

        this.cgapiService.getDomainCartData().subscribe(profile => this.domainCartcount = profile);

        this.formBuilder = new FormBuilder();
        this.searchForm = this.formBuilder.group({
          searchKey: [null],
        })
    }
     
  }

  onSearchSubmit() {
    if (isPlatformBrowser(this.platformId)) {
      if(this.searchForm.valid){
        
        this.searchKey = this.searchForm.value.searchKey;
        //this.searchKey = this.searchForm.get('searchKey');
        if(this.searchKey !='')
        {
          this.router.navigate(['explore/search/'+this.searchKey]);
        }
        
      }
      return false;
    }
  
  }

 

}
