<div class="main-banner">
  <div class="hvrbox"> <img loading="lazy" src="assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
    <div class="hvrbox-layer_top">
      <div class="container">
        <div class="overlay-text text-center">
          <h3>The Future Begins Here!</h3>
          <p>Explore and pick your webinar. For corporate bookings please send your details to us. <br>
            We will schedule and make a customized package for you. </p>
          <div class="col-md-8 offset-md-2 d-none">
            <form (submit)="onSubmit($event)">
            <div class="input-group">
              <input type="text" class="form-control searchTerm" name="searchKey"aria-label="Text input with dropdown button"
                placeholder="Enter Your Search Here">
              <div class="input-group-append">
                <button class="btn btn-search searchButton" type="button"><img loading="lazy" src="assets/imgs/search.png" alt="Image"></button>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="course-category-1x">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="title-middle mb-5">
            <h2>Industries</h2>
            <p></p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="category-left left-image">
            <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Banking-Finance.png" alt="slide 1" class="hvrbox-layer_bottom">
              <div class="hvrbox-layer_top">
                <div class="hvrbox-text"> <a href="#">Banking & Finance</a>
                  <h5>560 hours / 160 Lessons</h5>
                  <a href="#" class="btn-small">View Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="category-left right-image">
                <div class="hvrbox"> <img loading="lazy" src="assets/imgs/clinical.jpg" alt="slide 1" class="hvrbox-layer_bottom">
                  <div class="hvrbox-layer_top hvrbox-text">
                    <div class="hvrbox-text"> <a href="#">Clinical Research</a>
                      <h5>160 hours / 60 Lessons</h5>
                      <a href="#" class="btn-small">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="category-left right-image">
                <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Food-Safety.png" alt="slide 1" class="hvrbox-layer_bottom">
                  <div class="hvrbox-layer_top hvrbox-text">
                    <div class="hvrbox-text"> <a href="#">Food Safety</a>
                      <h5>50 hours / 50 Lessons</h5>
                      <a href="#" class="btn-small">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="category-left right-image">
                <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Healthcare-Medicare.jpg" alt="slide 1"
                    class="hvrbox-layer_bottom">
                  <div class="hvrbox-layer_top hvrbox-text">
                    <div class="hvrbox-text"> <a href="#">Healthcare & Medicare</a>
                      <h5>560 hours / 160 Lessons</h5>
                      <a href="#" class="btn-small">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="category-left right-image">
                <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Human-Resource.jpg" alt="slide 1"
                    class="hvrbox-layer_bottom">
                  <div class="hvrbox-layer_top hvrbox-text">
                    <div class="hvrbox-text"> <a href="#">Human Resource</a>
                      <h5>340 hours / 90 Lessons</h5>
                      <a href="#" class="btn-small">View Details</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="category-left right-image">
            <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Medical-Device.jpeg" alt="slide 1" class="hvrbox-layer_bottom">
              <div class="hvrbox-layer_top hvrbox-text">
                <div class="hvrbox-text"> <a href="#">Medical Device</a>
                  <h5>560 hours / 160 Lessons</h5>
                  <a href="#" class="btn-small">View Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="category-left right-image">
            <div class="hvrbox"> <img loading="lazy" src="assets/imgs/OSHA.jpg" alt="slide 1" class="hvrbox-layer_bottom">
              <div class="hvrbox-layer_top hvrbox-text">
                <div class="hvrbox-text"> <a href="#">OSHA</a>
                  <h5>560 hours / 160 Lessons</h5>
                  <a href="#" class="btn-small">View Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="category-left right-image">
            <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Pharmaceutical-Biotech.jpg" alt="slide 1"
                class="hvrbox-layer_bottom">
              <div class="hvrbox-layer_top hvrbox-text">
                <div class="hvrbox-text"> <a href="#">Pharmaceutical & Biotech</a>
                  <h5>560 hours / 160 Lessons</h5>
                  <a href="#" class="btn-small">View Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="category-left right-image">
            <div class="hvrbox"> <img loading="lazy" src="assets/imgs/Trade-Logistics.png" alt="slide 1" class="hvrbox-layer_bottom">
              <div class="hvrbox-layer_top hvrbox-text">
                <div class="hvrbox-text"> <a href="#">Trade & Logistics</a>
                  <h5>560 hours / 160 Lessons</h5>
                  <a href="#" class="btn-small">View Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="category-feature">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <div class="single-feature"> <img loading="lazy" src="assets/imgs/man-2.png" alt="slide 1">
                  <h4>Expert Instructors</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="single-feature"> <img loading="lazy" src="assets/imgs/clock.png" alt="slide 1">
                  <h4>Lifetime Access</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="single-feature"> <img loading="lazy" src="assets/imgs/pc.png" alt="slide 1">
                  <h4>Learn Anywhere</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>