<app-header></app-header>


<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Checkout Information
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-details-1x">

            <div class="row">
                
                <div class="col-lg-12" *ngIf="cartData['result']">
                    <form action="#">
                        <div class="table-content table-responsive cart-table-content header-color-gray">
                            <table>
                                <thead>
                                    <tr class="bg-gray">
                                        <th class="product-name">Item</th>
                                        <th class="product-price"> Price</th>
                                        <th>Attendee</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    <tr *ngFor="let singleCart of cartData['result']; let i = index">
                                        <td class="product-name">{{ i + 1 }}. <a href="#"></a>{{singleCart['item_details']['title']}}</td>

                                        <td class="product-price" >
                                            <div *ngFor="let singleprice of singleCart['pricelist']">
                                                <span class="amount" *ngIf="singleprice['selected'] ===1">${{singleprice['amount']}}</span>
                                            </div>
                                            
                                        </td>

                                        <td class="cart-quality">
                                            <div *ngFor="let singleprice of singleCart['pricelist']">
                                                <span class="amount" *ngIf="singleprice['selected'] ===1">{{singleprice['name']}}</span>
                                            </div>
                                            <!--
                                            <div class="input-group">
                                                <input type="button" value="-" class="button-minus"
                                                    data-field="quantity">
                                                <input type="number" step="0" max="" value="1" name="quantity"
                                                    class="quantity-field">
                                                <input type="button" value="+" class="button-plus"
                                                    data-field="quantity">
                                            </div>
                                            -->
                                        </td>
                                        <td class="price-total">
                                                <span class="amount" >${{singleCart['rowTotalAmount']}}</span>
                                            
                                        </td>
                                        <td class="product-remove">
                                            <a (click)="deleteCart(singleCart['id'])"><i class="far fa-times-circle"></i></a>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </form>
                    <div class="shoping-update-area row">
                        <div class="continue-shopping-butotn col-6 mt-30">
                            <a routerLink="/" class="btn btn--lg btn--black"> Continue
                                Shopping </a>
                        </div>

                        <div class="update-cart-button col-6 text-right mt-30">
                            
                               <a class="" target="_blank" routerLink="/refund"> See our Refund Policies</a>
                               
                        </div>
                    </div>
                    <div class="cart-buttom-area">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="discount-code section-space--mt_60">
                                    <h6 class="mb-30 font-weight-bold">Coupon Discount</h6>
                                    <p class="mb-20">Enter your coupon code if you have one.</p>
                                    <input type="text" name="name" placeholder="Coupon code">
                                    <button class="coupon-btn btn btn--lg btn--border_1" (click)="couponCheck()">Apply
                                        coupon</button>
                                    <span class="m-1 text-danger" *ngIf="couponInvalid">
                                        {{couponInvalid}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="cart_totals section-space--mt_60 ml-md-auto">
                                    <div class="grand-total-wrap">
                                        <div class="grand-total-content">
                                            <ul>
                                                <li>Subtotal <span> ${{cartData['totalAmount']}}</span></li>
                                                <li class="success">Coupon Discount <span>- $0</span></li>
                                                <li>Total <span>${{cartData['totalAmount']}}</span> </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="grand-btn mt-30">
                                        <button 
                                            class="btn btn--black btn--full text-center btn--lg" (click)="paymentEntry()">Proceed to
                                            checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-lg-12 text-center" *ngIf="!cartData['result']">
                    <h4>Oops Your cart is empty!</h4>
                     <br>
                    <h6>Looks like you haven't added anything to your cart yet.</h6>
                    <br>
                    <a routerLink="/" class="btn btn--lg btn--black">
                     Shop now </a>
                  </div>
            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>