<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Services</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>Customized Webinars & Online Boot Camps
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                    <p>With Customized Webinars & Online Boot Camps ComplainceIQ can deliver in- depth vocationally
                        related trainings tailored specifically to the needs of your Company, Team or Department.
                    </p>

                    <p> We are committed to helping Professionals and Teams navigate the complexities associated
                        with new and emerging regulatory and compliance Codes, Best Practices and Industry Trends
                        through our Best in Class training programs by the industry leading experts.</p>

                    <p>You can customise the “Areas Covered” to suite your requirement and we will have the webinar
                        delivered right to your Teams' desktop, at their convenience.</p>


                </div>



            </div>

        </div>
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>In-House Trainings
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                    <p>With a data base of 1000s of expert speakers from all over the world, we have the best
                        trainers and leading experts who can deliver Customized Trainings right in your office. The
                        benefits of this mode of trainings is that it has have proven to yield greater results in
                        assimilation, retention and knowledge utilisation and thus increasing resource productivity.




                    </p>

                    <p> You can maximize your professional development time, by engaging us, so that your Team can
                        sit back and absorb while we manage all the leg work.</p>

                    <p>With our vast support staff and global partners, we are sure to deliver the trainings of
                        your choice, at a level that you would have never experienced before.</p>


                </div>



            </div>

        </div>
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>Customized Seminars</h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-4">

            <div class="row">

                <div class="col-md-12">
                    <p>Would you like to host a training outside your office, for staff members or perhaps combine
                        an office outing with a Team training?




                    </p>

                    <p> Yes, we can do that for you, as we are tied up with several hotels, conference halls along
                        with host of the best trainers & experts across industries.</p>

                    <p>This highly effective form of training has proven to be a motivator for Employees who can
                        mix work with a small break, and results tend to show that these Employees return well
                        informed and refreshed.</p>


                </div>



            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>
