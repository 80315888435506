import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { WebinarapiService } from '../../../services/webinarapi.Service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public webinarListData = [];

  constructor(private cgapiService: WebinarapiService,
     private metaService: Meta,
     @Inject(PLATFORM_ID) private platformId: Object ) { }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
    this.metaService.addTag({ name: 'descriptionMI', 
                   content: 'This is an article about Angular Meta service' });
    this.cgapiService.getWebinarList()
      .subscribe( data => this.webinarListData = data['0']); 
    }
     // .subscribe( data =>console.log(data[0])); 
  }

}
