
import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ReactiveFormsModule,FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public domainListData = [];
  public currentYear: number;
  //public subForm;
  public processing = false;
  public buttonDisabled;
  public errorMessage;

  public subForm: FormGroup = new FormGroup({
    semail: new FormControl(''),
  });
  constructor(private cgapiService: WebinarapiService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object, ) { }

  ngOnInit() {

    this.currentYear = new Date().getFullYear();

    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getDomainDetails()
        .subscribe( data =>
          {
            if(data['0'].result.title != undefined)
            {
                this.domainListData['title'] = data['0'].result.title;
            }
            if(data['0'].result.contact != undefined)
            {
                this.domainListData['contact'] = data['0'].result.contact;
            }
            if(data['0'].result.email != undefined)
            {
                this.domainListData['email'] = data['0'].result.email;
            }
            if(data['0'].result.address1 != undefined)
            { 
                this.domainListData['address1'] = data['0'].result.address1;
            }
            if(data['0'].result.address2 != undefined)
            {
                this.domainListData['address2'] = data['0'].result.address2;
            }

            if(data['0'].result.company_title != undefined)
            {
                this.domainListData['companytitle'] = data['0'].result.company_title;
            }

            if(data['0'].result.extra_title != undefined)
            {
                this.domainListData['extratitle'] = data['0'].result.extra_title;
            }
            
          });

          this.formBuilder = new FormBuilder();
          this.subForm = this.formBuilder.group({
            semail: [null, [Validators.required, Validators.email]],
          })
        }
  }

  onSubmit(){

    if (isPlatformBrowser(this.platformId)) {
      if(this.subForm.valid){
        this.processing = true;
      var dataset =
        {
          'email':this.subForm.value.semail,
          //'email':this.subForm.get('semail')
        };
        
        this.cgapiService.postSubscription(dataset)
        .subscribe(data => {
          
          if(data.result != undefined && data.result == '200') {
            this.subForm.reset();
            this.buttonDisabled = false;
            this.processing = false;
            this.errorMessage ='Thank you for subscribing us. Please come back and see us often.';
          } else {
            this.buttonDisabled = true;
            this.processing = false;
            this.errorMessage ='Please try again.'
          }
        });
    } else {
      this.errorMessage ='Please enter a valid email !';
      
    }
  }
 }

}
