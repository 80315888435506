<app-header></app-header>
<div class="main-banner course-list-banner">
  <div class="hvrbox">
    <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
    <div class="hvrbox-layer_top">
      <div class="container">
        <div class="overlay-text text-center">
          <h3 class="font-weight-bold">Explore and pick your webinar.</h3>
          <div class="col-md-8 offset-md-2 d-none">
            <div class="input-group">
              <input type="text" class="form-control" aria-label="Text input with dropdown button"
                placeholder="What are you looking for?">
              <div class="input-group-append">
                <button class="btn btn-search" type="button"><i class="fas fa-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="course-header-1x">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h3  *ngIf="!itemNotfoundFlag">Showing results matching your search criteria</h3>
      </div>
      <div class="col-md-3">
        <div class="course-header-left">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingTwo">
                <a href="#" class="icon-right" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="true" aria-controls="collapseTwo">
                  <h3>Filter Courses</h3>
                </a>
              </div>
              <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                data-parent="#accordion">
                <div class="card-body">
                  <div class="filter-product">
                    <label class="container">Webinars
                      <input type="checkbox" checked="checked">
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">Video On-Demand
                      <input type="checkbox" checked="checked">
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">Seminars
                      <input type="checkbox">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingOne">
                <a href="#" class="icon-right" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <h3>Category</h3>
                </a>
              </div>

              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                data-parent="#accordion">
                <div class="card-body">
                  <div class="category">
                    <ul>
                      <li *ngFor="let singleIndustry of items['industry']">
                        <a routerLink="/explore/industry/{{singleIndustry['slug']}}" routerLinkActive="active" class="dropdown-item">{{singleIndustry['category_name']}} ({{singleIndustry['webinarcount']}})</a>
                      </li>
                      
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div *ngIf="itemNotfoundFlag">
          <h4>No result found.Please search with different keyword.</h4>

        </div>
        <div class="course-grid-list">
          <div class="all-course">
            <span *ngIf="loading">
              <div class="d-flex align-items-center">
                <strong>Loading...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
              </div>
            </span>
            
            
            <div class="row" *ngIf="items">
              
              
              <!-- Single Course -->
              <div class="col-md-4" *ngFor="let singleItem of items.result" >
                <div *ngIf="singleItem.upcoming_flag === 1; then thenBlock else elseBlock"></div>
                <ng-template #thenBlock>
                  <div class="single-course d-flex" style="border-top: 5px solid #228B22;">
                    <div class="single-course-content">
                      <a [routerLink]="['/webinar/', singleItem.webinar_id]">{{ singleItem.title }}</a>
                     <!-- <p class="details">{{ singleItem.description | removeHtmlTag | slice:0:170  }}</p>-->
                      
                      <p *ngIf="singleItem.upcoming_flag" class="text-success mb-1"> {{ singleItem.start_date|date }} - {{ singleItem.start_time }} EST</p>
                      <p title="Duration">Duration : {{ singleItem.duration }}<span></span> </p>
                      <h3>
                        <p class="text-info">{{ singleItem.category_name}} <span class="text-muted"><del>${{ singleItem.price + 111 }}</del> <b>{{ singleItem.price|currency }}</b></span></p>
                      </h3>
                      
                    </div>
                  </div>
              </ng-template>
              <ng-template #elseBlock>
                <div class="single-course d-flex" style="border-top: 5px solid #e42948;">
                  <div class="single-course-content">
                    <a [routerLink]="['/webinar/', singleItem.webinar_id]">{{ singleItem.title }}</a>
                    
                    <!--<p class="details">{{ singleItem.description | removeHtmlTag | slice:0:170  }}</p>-->
                    
                    <p *ngIf="singleItem.upcoming_flag" class="text-success mb-1"> {{ singleItem.start_date|date }} - {{ singleItem.start_time }} EST</p>
                    <p title="Duration">Duration : {{ singleItem.duration }}<span class="text-danger">Recorded <i class="fas fa-video"></i></span> </p>
                    <h3>
                      <p class="text-info">{{ singleItem.category_name}} <span class="text-muted"><del>${{ singleItem.price + 111 }}</del> <b>{{ singleItem.price|currency }}</b></span></p>
                    </h3>
                    
                   
                  </div>
                </div>
            </ng-template>
                </div>

            </div>

            
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
