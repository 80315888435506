<app-header></app-header>
<div class="main-banner course-list-banner">
  <div class="hvrbox">
    <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
    <div class="hvrbox-layer_top">
      <div class="container">
        <div class="overlay-text text-center">
          <h3 class="font-weight-bold">Explore and pick your webinar.</h3>
          <div class="col-md-8 offset-md-2 d-none">
            <div class="input-group">
              <input type="text" class="form-control" aria-label="Text input with dropdown button"
                placeholder="What are you looking for?">
              <div class="input-group-append">
                <button class="btn btn-search" type="button"><i class="fas fa-search"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="course-header-1x">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h3>Showing results matching your search criteria</h3>
      </div>
      <div class="col-md-3">
        <div class="course-header-left">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingTwo">
                <a href="#" class="icon-right" data-toggle="collapse" data-target="#collapseTwo"
                  aria-expanded="true" aria-controls="collapseTwo">
                  <h3>Filter Courses</h3>
                </a>
              </div>
              <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
                data-parent="#accordion">
                <div class="card-body">
                  <div class="filter-product">
                    <label class="container">Webinars
                      <input type="checkbox" checked="checked">
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">Video On-Demand
                      <input type="checkbox" checked="checked">
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">Seminars
                      <input type="checkbox">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingOne">
                <a href="#" class="icon-right" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  <h3>Category</h3>
                </a>
              </div>

              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                data-parent="#accordion">
                <div class="card-body">
                  <div class="category">
                    <ul>
                      <li><a href="#"> Banking & Finance <span>45</span></a></li>
                      <li><a href="#"> Clinical Research <span>30</span></a></li>
                      <li><a href="#"> Food safety <span>20</span></a></li>
                      <li><a href="#"> Healthcare & Medicare <span>15</span></a></li>
                      <li><a href="#"> Human Resource <span>30</span></a></li>
                      <li><a href="#"> Medical Device <span>20</span></a></li>
                      <li><a href="#"> OSHA <span>30</span></a></li>
                      <li><a href="#"> Pharmaceutical & Biotech <span>30</span></a></li>
                      <li><a href="#"> Trade & Logistics <span>30</span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">

        <div class="course-grid-list">
          <div class="all-course">
            <div class="row">
              <div class="col-md-4">
                <div class="single-course">
                  <div class="single-course-content">
                    <a href="course-details.html">Writing Effective sops for FDA and CLIA
                      Regulated Companies</a>
                    <p class="details">Notified bodies and the FDA cannot require your suppliers
                      to meet the quality system regulations...</p>
                    <p class="text-success mb-1"> Jun 2, 2020 - 01:00 PM EST</p>
                    <p>Joy L. McElroy <span><del>$169</del> <b>$149</b></span> </p>
                    <h3>
                      <p class="text-info">Webinar <span class="text-muted">Duration:
                          90mins</span></p>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="single-course">
                  <div class="single-course-content">
                    <a href="course-details.html">Writing Effective sops for FDA and CLIA
                      Regulated Companies</a>
                    <p class="details">Notified bodies and the FDA cannot require your suppliers
                      to meet the quality system regulations...</p>
                    <p class="text-success mb-1"> Jun 2, 2020 - 01:00 PM EST</p>
                    <p>Joy L. McElroy <span><del>$169</del> <b>$149</b></span> </p>
                    <h3>
                      <p class="text-danger">Video <span class="text-muted">Duration:
                          3hrs</span></p>
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="single-course">
                  <div class="single-course-content">
                    <a href="course-details.html">Writing Effective sops for FDA and CLIA
                      Regulated Companies</a>
                    <p class="details">Notified bodies and the FDA cannot require your suppliers
                      to meet the quality system regulations...</p>
                    <p class="text-success mb-1"> Jun 2, 2020 - 01:00 PM EST</p>
                    <p>Joy L. McElroy <span><del>$169</del> <b>$149</b></span> </p>
                    <h3>
                      <p class="text-info">Webinar <span class="text-muted">Duration:
                          90mins</span></p>
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="single-course">
                  <div class="single-course-content">
                    <a href="course-details.html">Writing Effective sops for FDA and CLIA
                      Regulated Companies</a>
                    <p class="details">Notified bodies and the FDA cannot require your suppliers
                      to meet the quality system regulations...</p>
                    <p class="text-success mb-1"> Jun 2, 2020 - 01:00 PM EST</p>
                    <p>Joy L. McElroy <span><del>$169</del> <b>$149</b></span> </p>
                    <h3>
                      <p class="text-info">Webinar <span class="text-muted">Duration:
                          90mins</span></p>
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="single-course">
                  <div class="single-course-content">
                    <a href="course-details.html">Writing Effective sops for FDA and CLIA
                      Regulated Companies</a>
                    <p class="details">Notified bodies and the FDA cannot require your suppliers
                      to meet the quality system regulations...</p>
                    <p class="text-success mb-1"> Jun 2, 2020 - 01:00 PM EST</p>
                    <p>Joy L. McElroy <span><del>$169</del> <b>$149</b></span> </p>
                    <h3>
                      <p class="text-danger">Video <span class="text-muted">Duration:
                          3hrs</span></p>
                    </h3>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="single-course">
                  <div class="single-course-content">
                    <a href="course-details.html">Writing Effective sops for FDA and CLIA
                      Regulated Companies</a>
                    <p class="details">Notified bodies and the FDA cannot require your suppliers
                      to meet the quality system regulations...</p>
                    <p class="text-success mb-1"> Jun 2, 2020 - 01:00 PM EST</p>
                    <p>Joy L. McElroy <span><del>$169</del> <b>$149</b></span> </p>
                    <h3>
                      <p class="text-info">Webinar <span class="text-muted">Duration:
                          90mins</span></p>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="course-pagination">
                  <ul class="pagination">
                    <li class="page-item active"><span class="page-link">1</span></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                    <li class="page-item"><a class="page-link" href="#"><i
                          class="fas fa-angle-right"></i></a></li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
