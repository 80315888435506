<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Contact</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-us-1x">
    <div class="container" *ngIf="domainListData">
        
        <div class="course-details-1x p-3 mb-5">

          <div class="row">

            <!-- contact form -->
            <div class="col-md-6 col-sm-12">
              <div class="contact-form" *ngIf="showContactMessage">
                
                  <h5>Thank you for contacting us.</h5>
                   
                   We appreciate that you’ve taken the time to write us We’ll get back to you very soon. Please come back and see us often.
                
              </div>
              <div class="contact-form" *ngIf="!showContactMessage">
                <h4>Send A Message</h4>
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" >
                  <div class="form-group">
                    <!--<label>Name</label>-->
                  <input class="input" class="form-control" type="text" formControlName="contactName" placeholder="Please enter your name">
                  </div>
                  <div class="form-group">
                    <!--<label>Email</label>-->
                  <input class="input" class="form-control" type="email" formControlName="email" placeholder="Please enter your email">
                  </div>
                  <div class="form-group">
                  <!--  <label>Subject</label>-->
                  <input class="input"  class="form-control" type="text" formControlName="subject" placeholder="Please enter a subject">
                  </div>
                  <div class="form-group">
                    <!--<label>Message</label>-->
                  <textarea class="input" class="form-control" formControlName="message" placeholder="Please enter your message"></textarea>
                  </div>
             
                  <div *ngIf="errorMessage">
                    <span class="text-danger">{{errorMessage}}</span> 
                  </div>
                  <button class="btn btn--black btn--full text-center btn--lg" disabled="{{buttonDisabled}}" type="submit">Submit</button>
                  <div class="" >
                    <img  *ngIf="processing" src="assets/img/loading.gif" /> 
                  </div>
                  
                </form>
              </div>
            </div>
            <!-- /contact form -->
      
            <!-- contact information -->
            <div class="col-md-6 col-sm-12 col-md-offset-1">
              
              <div class="contact-address">
                <h2>Contact Information</h2>
                <ul class="" *ngIf="domainListData">
              
                
                  <li class=""><i class="fa fa-phone"></i><a href="tel:{{domainListData.contact}}"> {{domainListData.contact}}</a></li>
                  <li class=""><i class="fa fa-envelope"></i>  <a href="mailto:{{domainListData.email}}"> {{domainListData.email}}</a></li>
                  <li class=""><i class="fa fa-map-marker"></i>
                    {{domainListData.address1}}{{domainListData.address2}}</li>
                </ul>
              </div>
              <!-- contact map -->
              <div id="contact-map"></div>
              <!-- /contact map -->
      
            </div>
            <!-- contact information -->
      
          </div>

        </div>
        
        
        
        
    </div>
</div>

<app-footer></app-footer>
