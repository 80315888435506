<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Customer Support</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>Customer Support Details
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                    <div class="privacy-policy-content" *ngIf="domainListData">
                         
                        <div class="privacy-policy-content">
                            <p>We value your choice of {{domainListData.title}} for online training.</p>
                            
                            {{domainListData.title}} customer support have been proven to help organizations reduce risk, improve compliance, and protect their reputation.

                            If you are looking for a way to ensure that your employees have the knowledge and skills they need to stay compliant, {{domainListData.title}} can help.

                            <a routerLink="/contact">Contact</a> {{domainListData.title}} customer support team today to learn more about our corporate training programs.
                         </div>
                        <h3>For further information, please send your queries from our <a routerLink="/contact">Contact Us</a> page.</h3>
                        
                     </div>

                </div>



            </div>

        </div>
        
    </div>
</div>

<app-footer></app-footer>
