
import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit{
  public domainListData = [];
  public items = [];
  public loading = true;
  public searchKey = '';
  public itemNotfoundFlag:boolean=false;

  constructor(private cgapiService: WebinarapiService,
     private route: ActivatedRoute,
     private router: Router,
     @Inject(PLATFORM_ID) private platformId: Object) {
      this.router.routeReuseStrategy.shouldReuseRoute = function() {
          return false;
      };
      }

  ngOnInit() {
      let type = this.route.snapshot.paramMap.get('type');

      if (isPlatformBrowser(this.platformId)) {
      if(type =='industry') {
        let key = this.route.snapshot.paramMap.get('key');
          var dataset = {
            'industry' : key
          };
          this.cgapiService.getExploreList(dataset)
            .subscribe( 
              
              data => {
                this.processData(data);
              }
            
              ); 
          }
          if(type =='webinar' ) {

            this.cgapiService.getWebinarList()
              .subscribe( 
                
                data => {
                  this.processData(data);
                }
              
                ); 
          }
          if(type =='record') {

            this.cgapiService.getHomeVideoList()
              .subscribe( 
                
                data => {
                  this.processData(data);
                }
              
                ); 
          }

          if(type == 'search') {
            this.route.params.subscribe(val => {
              this.searchKey = val.key;
              this.keySearch();
            });
            this.searchKey = this.route.snapshot.paramMap.get('key');
            this.keySearch();
          }

          this.cgapiService.getAllIndustries([])
              .subscribe( 
                
                data => {
                  this.processData(data,'industry');
                }
              
                ); 
        }
      }
    

      processData(data,type='') {

                if(data['0'].result != undefined)
                {
                  const mappedArr = Object.keys(data['0'].result).map(
                    key => (data['0'].result[key])
                    );
                  
                  if(type == 'industry') {
                    this.items['industry'] = mappedArr;
                  }
                  else {
                    this.items['result'] = mappedArr;
                  }
                }
                //Disable loader
                setTimeout(() => {
                    this.loading = false;
                }, 4000);

                if(data['0'].status == 400)
                {
                    this.itemNotfoundFlag = true;
                }
      }

  keySearch()
  {

    if (isPlatformBrowser(this.platformId)) {
    this.loading = true;
    var dataset =
    {
      'search_key' : this.searchKey
    };
    this.cgapiService.postKeySearch(dataset)
      .subscribe( 
        data => {

          this.processData(data);
        }
       
        ); 
      }
  }

}
