<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Refund</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container" *ngIf="domainListData">
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>For Live Events (Webinars)
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                  <p>
                    Participants of our live events, may cancel up to <strong>48 working hours</strong> prior to the event start date and will receive a 
                    letter of credit to be used towards a future event, valid up to one year from the date of issuance.
                </p>
                
                <p>
                    We at “{{domainListData.title}}.” would process/provide refund only if the event has been canceled.
                </p>
                <p>
                   In case of a canceled webinar, the participant can choose between the recorded version of the 
                   webinar or for a refund. Refunds will not be given to participants 
                   who do not show up for the webinar. We might provide a recorded link, in exchange, based on the participants’ request.
                </p>
                <p>
                    A Webinar may be canceled due to lack of enrolment or due to unavoidable factors.
                    <strong>All participants will be notified 24 hours in advance in case a webinar is canceled.</strong>
                </p>


                </div>



            </div>

        </div>
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>For Recorded Sessions
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                  <p>
                    Requests for access to the recorded version of the webinar will be processed only after the webinar has occurred. 
                    </p>
                                    <p>
                    Recorded Video: 
                    Thepurchaser will receive a unique link to recorded video within 3 business days of placing an order.
                    </p>
                                    <p>
                    Attendees who have purchased a combo of live session and recorded video will receive a link 
                    to the recorded video within 3 business days following the date when webinar has occurred. 
                    </p>
                                    <p>
                    No refund will be issued once the link has been sent to the attendee/purchaser.
                     In case of an issue with recorded video or its link, the same must be communicated to us within 3 business days.  
                    </p>
                                    <p>
                    Training CD-DVD:</p>
                    <ul>
                        <li>Order will be dispatched after 72 hours on completion of payment.</li>
                    <li>	Order will be dispatched after 72 hours on webinar completion (if a combo of live session and training CD-DVD has been purchased).</li>
                    <li>	No cancelations will be accepted — nor refunds issued — if the order is dispatched from our side and tracking information is provided to the purchaser.
                    </li>
                    <li>	For damaged/defective item, replacement will be offered if the issue is reported within 7 days.</li>
                    </ul>
                Note: Compliance -- will not be liable for any penalties or other expenditure 
                incurred due to delay in delivery by courier. Please double-check your address carefully 
                when placing an order.


                </div>



            </div>

        </div>
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>For In-Person Events (Seminars):</h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-4">

            <div class="row">

                <div class="col-md-12">
                  <p>Cancelation:

                    The Participant(s) (the person who has registered for this conference) need to submit a written cancelation request through fax or email at least seven calendar days prior to the start date of the event. In that case, we will issue a refund— less a $300 administration fee.
                    </p><p>
                    No cancelations will be accepted — nor refunds issued — if we receive a request in seven calendar days or less from the start date of the event.
                    </p><p>
                    A Seminar may be canceled due to lack of enrolment or due to unavoidable factors. All participants will be notified seven days prior to the start date of Seminar.
                    </p><p>
                    In the event ‘{{domainListData.title}} cancels a seminar, we are not responsible for any airfare, hotel, other costs or losses incurred by registrants.
                    </p><p>
                    Rescheduling / Substitution:
                    Participants can request us, either via fax or e-mail (two calendar days prior to the seminar); for rescheduling/substitution.
                    </p><p>
                    In such a case, we will issue the participant a credit for the amount paid minus administration fees ($300).
                    </p><p>
                    The same, can be used to attend any future ‘Compliance {{domainListData.title}}” event and will be valid for a period of one year.
                    </p><p>
                    No Show:
                    No-shows will be charged the full amount.
        
                    </p><p>
                    Contact Details:
        
                    For refunds/exchange/substitutions, please email us at {{domainListData.email}}. Alternatively, 
                    you can  click on <a class="main-button icon-button" routerLink="/contact">Contact us </a>
                    
                    </p>


                </div>



            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>
