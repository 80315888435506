
import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})

export class BannerComponent implements OnInit {
  public searchKey: string;
  constructor(private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) { }


    ngOnInit(): void{}

    
  onSubmit(event: any) {
    if (isPlatformBrowser(this.platformId)) {
    this.searchKey = event.target.searchKey.value;
    
      if(this.searchKey !='')
      {
        this.router.navigate(['search/'+this.searchKey]);
      }

      return false;
    }
  }

}

