<div class="main-menu-1x">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="main-menu">
          <nav class="navbar navbar-expand-lg navbar-light bg-light btco-hover-menu"> 
            <a class="navbar-brand"  *ngIf="domainListData"
            routerLink="/" > <b> {{domainListData.title}} </b> 
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span
                class="navbar-toggler-icon"></span> </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">

              <ul class="navbar-nav ml-auto main-menu-nav">
                <li class="nav-item"></li>
                <li class="nav-item dropdown active"> <a class="nav-link" 
                    id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Industries </a>

                    
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
                    <li><a class="dropdown-item" routerLink="/explore/industry/Banking-Finance" routerLinkActive="active">Banking & Finance</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Clinical-Research" routerLinkActive="active">Clinical Research</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Food-safety" routerLinkActive="active">Food safety</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Healthcare-Medicare" routerLinkActive="active">Healthcare & Medicare</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Human-Resource" routerLinkActive="active">Human Resource</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Medical-Device" routerLinkActive="active">Medical Device</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/OSHA" routerLinkActive="active">OSHA</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Pharmaceutical-Biotech" routerLinkActive="active">Pharmaceutical-Biotech</a></li>
                    <li><a class="dropdown-item" routerLink="/explore/industry/Trade-Logistics" routerLinkActive="active">Trade Logistics</a></li>
                  </ul>
                </li>
                <li class="nav-item"> <a class="nav-link"routerLink="/service" routerLinkActive="active">Services</a> </li>
                <li class="nav-item"> <a class="nav-link"routerLink="/contact" routerLinkActive="active">Contact</a> </li>
                <li class="nav-item sign-in">
                  <form [formGroup]="searchForm" *ngIf="searchForm" (ngSubmit)="onSearchSubmit()" >
                  <div class="input-group overlay-text">
                    <input type="text" formControlName="searchKey"
                     class="form-control" aria-label="Text input with dropdown button"
                      placeholder="Enter Your Search Here">
                    <div class="input-group-append">
                      <button class="btn btn-search" type="submit"><img src="assets/imgs/search.png"
                          alt="Image"></button>
                    </div>
                  </div>
                  </form>
                </li>
                <li class="nav-item">
                  <a class="btn-small px-3" routerLink="/cart" routerLinkActive="active">
                    <i class="fas fa-shopping-cart fa-lg"></i> 
                    <!--{{ domainListData['cartcount'] }}-->
                    {{ domainCartcount }}
                  </a>
                </li>
                <!--
                <li class="nav-item">
                  <a class="dropdown-item" routerLink="/login" routerLinkActive="active">Login</a>
                </li>
                -->
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</div>
