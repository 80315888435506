
  <div class="course-1x" *ngIf="homeWebinarListData">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="title-middle mb-5">
            <h2> Upcoming Webinars</h2>
          </div>
        </div>
        <div class="col-md-12">
          <div class="col-md-12 col-sm-12 text-center" >
            <img  *ngIf="loading" src="assets/imgs/loading.gif" /> 
          </div>
          <div class="all-course">
            <div class="row"  >
              <div class="col-md-3"  *ngFor="let singleWebinar of homeWebinarListData.result">
                <div class="single-course d-flex" style="border-top: 5px solid #228B22;">
                  <div class="single-course-content">
                    <a [routerLink]="['/webinar/', singleWebinar.webinar_id]" title="{{ singleWebinar.title }}">
                      {{ singleWebinar.title?.length>60 ? (singleWebinar.title | slice:0:60)+'..' : (singleWebinar.title) }}  
                    </a>
                    <!--<p class="text-success mb-1"> {{ singleWebinar.start_date | date }}</p>-->
                    <p>{{ singleWebinar.start_date | date }} <span><del>{{ singleWebinar.price | currency }}</del> <b>{{ singleWebinar.price | currency }}</b></span> </p>
                    <h3>
                      <p class="text-info"> <span class="text-muted">
                        
                        Duration: {{ singleWebinar.duration}}</span></p>
                    </h3>
                  </div>
                </div>
              </div>
              
              
              <div class="col-md-12 text-center"> <a routerLink="/explore/webinar/all" class="btn-small"> View more </a> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




