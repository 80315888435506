<div class="main-banner">
  <div class="hvrbox"> <img src="assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
    <div class="hvrbox-layer_top">
      <div class="container">
        <div class="overlay-text text-center">
          <h3>The Future Begins Here!</h3>
          <p>Explore and pick your webinar. For corporate bookings please send your details to us. <br>
            We will schedule and make a customized package for you. </p>
          <div class="col-md-8 offset-md-2 d-none">
            <form (submit)="onSubmit($event)">
            <div class="input-group">
              <input type="text" class="form-control searchTerm" name="searchKey"aria-label="Text input with dropdown button"
                placeholder="Enter Your Search Here">
              <div class="input-group-append">
                <button class="btn btn-search searchButton" type="button"><img src="assets/imgs/search.png" alt="Image"></button>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="course-category-1x">
  <div class="container">
    <div class="row" *ngIf="items">
      <div class="col-md-12">
        <div class="title-middle mb-5">
          <h2>Industries</h2>
          <p></p>
        </div>
      </div>
      <div class="col-md-6" *ngIf="items.result">
        <div class="category-left left-image">
          <div class="hvrbox"> <img src="assets/imgs/Banking-Finance.png" alt="slide 1" class="hvrbox-layer_bottom">
            <div class="hvrbox-layer_top">
              <div class="hvrbox-text" > <a routerLink="/explore/industry/Banking-Finance">{{items.result[0].category_name}}</a>
                <h5>{{items.result[0].webinarhrcount}} hours / {{items.result[0].webinarcount}} Lessons</h5>
                <a routerLink="/explore/industry/Banking-Finance" class="btn-small">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" *ngIf="items.result">
        <div class="row">
          <div class="col-md-6">
            <div class="category-left right-image">
              <div class="hvrbox"> <img src="assets/imgs/{{items.result[1].category_image_asset}}" alt="slide 1" class="hvrbox-layer_bottom">
                <div class="hvrbox-layer_top hvrbox-text">
                  <div class="hvrbox-text"> <a routerLink="/explore/industry/Clinical-Research">{{items.result[1].category_name}}</a>
                    <h5>{{items.result[1].webinarhrcount}} hours / {{items.result[1].webinarcount}} Lessons</h5>
                    <a routerLink="/explore/industry/Clinical-Research" class="btn-small">View Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="category-left right-image">
              <div class="hvrbox"> <img src="assets/imgs/Food-Safety.png" alt="slide 1" class="hvrbox-layer_bottom">
                <div class="hvrbox-layer_top hvrbox-text">
                  <div class="hvrbox-text"> <a  routerLink="/explore/industry/Food-safety">{{items.result[2].category_name}}</a>
                    <h5>{{items.result[2].webinarhrcount}} hours / {{items.result[2].webinarcount}} Lessons</h5>
                    <a  routerLink="/explore/industry/Food-safety" class="btn-small">View Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="category-left right-image">
              <div class="hvrbox"> <img src="assets/imgs/Healthcare-Medicare.jpg" alt="slide 1"
                  class="hvrbox-layer_bottom">
                <div class="hvrbox-layer_top hvrbox-text">
                  <div class="hvrbox-text"> <a routerLink="/explore/industry/Healthcare-Medicare">{{items.result[3].category_name}}</a>
                    <h5>{{items.result[3].webinarhrcount}} hours / {{items.result[3].webinarcount}} Lessons</h5>
                    <a routerLink="/explore/industry/Healthcare-Medicare" class="btn-small">View Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="category-left right-image">
              <div class="hvrbox"> <img src="assets/imgs/Human-Resource.jpg" alt="slide 1"
                  class="hvrbox-layer_bottom">
                <div class="hvrbox-layer_top hvrbox-text">
                  <div class="hvrbox-text"> <a routerLink="/explore/industry/Human-Resource">{{items.result[4].category_name}}</a>
                    <h5>{{items.result[4].webinarhrcount}} hours / {{items.result[4].webinarcount}} Lessons</h5>
                    <a routerLink="/explore/industry/Human-Resource" class="btn-small">View Details</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="items.result">
        <div class="category-left right-image">
          <div class="hvrbox"> <img src="assets/imgs/Medical-Device.jpeg" alt="slide 1" class="hvrbox-layer_bottom">
            <div class="hvrbox-layer_top hvrbox-text">
              <div class="hvrbox-text"> <a routerLink="/explore/industry/Medical-Device">{{items.result[5].category_name}}</a>
                <h5>{{items.result[5].webinarhrcount}} hours / {{items.result[5].webinarcount}} Lessons</h5>
                <a routerLink="/explore/industry/Medical-Device" class="btn-small">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="items.result">
        <div class="category-left right-image">
          <div class="hvrbox"> <img src="assets/imgs/OSHA.jpg" alt="slide 1" class="hvrbox-layer_bottom">
            <div class="hvrbox-layer_top hvrbox-text">
              <div class="hvrbox-text"> <a routerLink="/explore/industry/OSHA">{{items.result[6].category_name}}</a>
                <h5>{{items.result[6].webinarhrcount}} hours / {{items.result[6].webinarcount}} Lessons</h5>
                <a routerLink="/explore/industry/OSHA" class="btn-small">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="items.result">
        <div class="category-left right-image">
          <div class="hvrbox"> <img src="assets/imgs/Pharmaceutical-Biotech.jpg" alt="slide 1"
              class="hvrbox-layer_bottom">
            <div class="hvrbox-layer_top hvrbox-text">
              <div class="hvrbox-text"> <a routerLink="/explore/industry/Pharmaceutical-Biotech">{{items.result[7].category_name}}</a>
                <h5>{{items.result[7].webinarhrcount}} hours / {{items.result[7].webinarcount}} Lessons</h5>
                <a routerLink="/explore/industry/Pharmaceutical-Biotech" class="btn-small">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="items.result">
        <div class="category-left right-image">
          <div class="hvrbox"> <img src="assets/imgs/Trade-Logistics.png" alt="slide 1" class="hvrbox-layer_bottom">
            <div class="hvrbox-layer_top hvrbox-text">
              <div class="hvrbox-text"> <a routerLink="/explore/industry/Trade-Logistics">{{items.result[8].category_name}}</a>
                <h5>{{items.result[8].webinarhrcount}} hours / {{items.result[8].webinarcount}} Lessons</h5>
                <a routerLink="/explore/industry/Trade-Logistics" class="btn-small">View Details</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="category-feature">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="single-feature"> <img src="assets/imgs/man-2.png" alt="slide 1">
                <h4>Expert Instructors</h4>
                <p>We have expert instructor who are specialist in department. </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="single-feature"> <img src="assets/imgs/clock.png" alt="slide 1">
                <h4>Lifetime Access</h4>
                <p>You will get lifetime access of the webinars on purchase of recorded USB</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="single-feature"> <img src="assets/imgs/pc.png" alt="slide 1">
                <h4>Learn Anywhere</h4>
                <p>Learning has no limit.Start learning today</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>