
import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { Title }     from '@angular/platform-browser';
import { Webinar,HomeWebinar } from 'src/app/models/webinar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-authheader',
  templateUrl: './authheader.component.html',
  styleUrls: ['./authheader.component.css']
})
export class AuthheaderComponent implements OnInit {

  domainListData: any[] = [];
  constructor(private cgapiService: WebinarapiService,private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: Object) {
     
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
    this.titleService.setTitle( window.location.hostname );
    this.cgapiService.getDomainDetails()
      .subscribe( data =>
        {
          this.titleService.setTitle( data['0'].result.title );
          //this.domainListData = JSON.parse(data['0'].result);
          if(data['0'].result.title != undefined)
          {
              this.domainListData['title'] = data['0'].result.title;
          }
      });
    }
  }

}
