import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';
import { ReactiveFormsModule,FormBuilder,FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  public cartData = [];
  public cartId;
  public couponInvalid;

  constructor(private cgapiService: WebinarapiService  ,private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {

    this.cartId = this.cookieService.get('webinar_ses');
    if (isPlatformBrowser(this.platformId)) {
      if(this.cartId != '') {
        this.cgapiService.getCart(this.cartId)
        .subscribe( 
          data => {
            if(data.result != undefined)
            {
              const mappedArr = Object.keys(data.result['cart']).map(
                key => (data.result['cart'][key])
                );
              this.cartData['result'] = mappedArr;

              this.cartData['totalAmount'] = data.result['totalAmount'];
            }

            //console.log( data);
          }
          
          );
      }
    }

     
  }

  couponCheck(){
    //console.log('Coupon');
    this.couponInvalid = 'Please enter a valid Coupon';
  }

  paymentEntry() {
    this.router.navigate(['/payment']);
  }

  deleteCart(id) {

    this.cartId = this.cookieService.get('webinar_ses');
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.deleteCart(this.cartId,id)
        .subscribe( 
          data => {
            /*if(data.result != undefined)
            {
              const mappedArr = Object.keys(data.result).map(
                key => (data.result[key])
                );
              this.cartData['result'] = mappedArr;
            }*/
            this.router.navigate(['/cart']);
            window.location.reload();
          }
          
          );
          this.router.navigate(['/cart']);
            return false;
        }
  }

  

}
