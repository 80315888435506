<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Privacy Policy</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>Privacy Policy Details
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">

            <div class="row">

                <div class="col-md-12">
                    <div class="privacy-policy-content">
                        <p *ngIf="domainListData">At <b>{{domainListData.title}}</b>, we prioritize data protection and user privacy. Our Privacy Policy underscores our commitment to delivering high-quality products and services with the utmost integrity in our interactions with clients. This policy outlines how we collect customer information, adapt to evolving practices, and respect user privacy. Please note that we reserve the right to modify this privacy statement as legal provisions and practices evolve.</p>
                        <h3>Personal Identification Information</h3>
                        <p>We collect personal identification information from users when they visit our site, register, subscribe to our newsletter, or engage in site activities. Users may be asked for their email address, but visiting our site can be done anonymously. We collect personal information only when users voluntarily provide it. Users may decline to share personal information, which might limit access to certain site features.</p>
                        <h3>Automated Data Collection and Browser Processing</h3>
                        <p>When visiting our site without registering or making transactions, our server automatically collects and temporarily stores data such as browser type, operating system, file inquiries, HTTP response codes, referring URLs, IP addresses, and server inquiry times. We do not analyze this data for personal identification or aggregate it with other sources, except for the IP address associated with orders.</p>
                        <h3>Collecting and Processing Data Provided Voluntarily</h3>
                        <p>Users can visit our site without logging in, but providing personal data (name, address, date of birth, email, phone number) through email or our website is voluntary. We use this information to manage contractual relationships, inquiries, and orders. Users can provide personal data when using free services, such as subscribing to the newsletter, contacting us, or registering for membership. Not providing this data may limit access to services.</p>
                        <h3>Data Disclosure to Third Parties</h3>
                        <p>We do not share personal data with third parties without user consent, except to process requests, orders, or provide services. Subcontractors will use the data exclusively for order fulfillment.</p>
                        <h3>Right of Revocation</h3>
                        <p>Users can revoke consent for data use, processing, and dissemination for purposes such as market research, advertising, and newsletters. This can be done at any time by sending a message using contactus page. Newsletter subscribers can unsubscribe by clicking the link in the newsletter.</p>
                        <h3>Use of Cookies</h3>
                        <p>Our website uses cookies to enhance user-friendliness, effectiveness, and security. Most browsers accept cookies by default, but users can enable or disable them in security settings. Deactivating cookies may affect site features</p>
                        <h3>Security of Transmitted Data</h3>
                        <p>We encrypt personal data before transmission, and payment transactions adhere to high security standards.</p>
                        <h3>Right to Information and Correction</h3>
                        <p>Users can request information about the personal data we have collected and stored, and have the right to correct, block, or delete their personal data.</p>
                        <h3>For further information, please visit <a routerLink="/contact">Contact Us</a>page.</h3>
                        
                     </div>

                </div>



            </div>

        </div>
        
    </div>
</div>

<app-footer></app-footer>
