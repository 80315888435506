<!-- Footer -->

<footer class="footer-section-1x">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="footer-top">
          <div class="row">
            <div class="col-md-4">
              <div class="footer-top-right"  *ngIf="domainListData"> <h4>{{domainListData.title}}</h4>
                <h6>Powered by {{domainListData.companytitle}}</h6>
                <h6 *ngIf="domainListData.extratitle">{{domainListData.extratitle}}</h6>
                <p>{{domainListData.address1}} {{domainListData.address2}}</p>
                <ul class="mt-2">
                  <li>Contact: <a href="tel:{{domainListData.contact}}">{{domainListData.contact}}</a> </li>
                  <li>Email: <a href="mailto:{{domainListData.email}}">{{domainListData.email}}</a></li>
                </ul>
                <div class="footer-social-link mt-1">
                  <ul>
                    <li><a href="#"> <i class="fab fa-facebook-f"></i> </a></li>
                    <li><a href="#"> <i class="fab fa-instagram"></i> </a></li>
                    <li><a href="#"> <i class="fab fa-twitter"></i> </a></li>
                    <li><a href="#"> <i class="fab fa-google-plus-g"></i> </a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="footer-top-left">
                <div class="row">
                  <div class="col-md-4">
                    <div class="single-link" >
                      <h3  *ngIf="domainListData">{{domainListData.title}}</h3>
                      <ul>
                        <li><a routerLink="/privacy"> Privacy Policy </a></li>
                        <li><a routerLink="/terms"> Terms of Services </a></li>
                        <li><a routerLink="/support"> Customer Support </a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="single-link">
                      <h3>Subscribe to our newsletter</h3>
                      <div class="footer-subscriber">
                        <form [formGroup]="subForm" *ngIf="subForm" (ngSubmit)="onSubmit()" >
                          <div class="input-group">
                            <input type="text" class="form-control"
                              formControlName="semail"
                             placeholder="Enter Your Email"
                              aria-label="Recipient's username">
                            <div class="input-group-append">
                              <button class="btn btn-subscriber" type="submit"> 
                                <i class="far fa-paper-plane"></i>
                              </button>
                            </div>
                            
                          </div>
                          <div class="input-group">
                            {{errorMessage}}
                            </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <p  *ngIf="domainListData">© {{currentYear}} {{domainListData.title}}. All Rights Reserved. </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- /Footer -->



