

import { Component, PLATFORM_ID, Inject, OnInit  } from '@angular/core';
import {isPlatformBrowser  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrls: ['./termsofservice.component.css']
})
export class TermsofserviceComponent implements OnInit{

  domainListData: any[] = [];

  constructor(private cgapiService: WebinarapiService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getDomainDetails()
        .subscribe( data => {
          if(data['0'].result.title != undefined)
          {
              this.domainListData['title'] = data['0'].result.title;
              console.log(this.domainListData['title'])
          }
          
        }); 

      }
  }
}
