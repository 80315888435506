import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule, Title }  from '@angular/platform-browser';

import { ContactComponent } from './component/pages/contact/contact.component';
import { HomeComponent } from './component/pages/home/home.component';
import { WebinarComponent } from './component/pages/webinar/webinar.component';
import { SeminarComponent } from './component/pages/seminar/seminar.component';
import { WebinarDetailsComponent } from './component/pages/webinar-details/webinar-details.component';
import { IndustriesComponent } from './component/pages/industries/industries.component';
import { IndustrydetailsComponent } from './component/pages/industrydetails/industrydetails.component';
import { RefundComponent } from './component/pages/refund/refund.component';
import { PaymentComponent } from './component/pages/payment/payment.component';
import { SearchComponent } from './component/pages/search/search.component';
import { ServiceComponent } from './component/pages/service/service.component';
import { LoginComponent } from './component/pages/login/login.component';

import { DashboardComponent } from './component/authpages/dashboard/dashboard.component';
import { ExploreComponent } from './component/pages/explore/explore.component';
import { CartComponent } from './component/pages/cart/cart.component';

import { PrivacypolicydetailsComponent } from './component/pages/privacypolicydetails/privacypolicydetails.component';
import { TermsofserviceComponent } from './component/pages/termsofservice/termsofservice.component';
import { CustomersupportComponent } from './component/pages/customersupport/customersupport.component';

//import { SeminarDetailsComponent } from './component/pages/seminar-details/seminar-details.component';
//ng g component component/pages/payment 

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'webinars', component: ExploreComponent },
  { path: 'seminars', component: ExploreComponent },
  { path: 'webinar/:id', component: WebinarDetailsComponent },
  { path: 'industries', component: IndustriesComponent },
  { path: 'industries/:id', component: IndustrydetailsComponent },
  { path: 'refund', component: RefundComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'search/:key', component: SearchComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'explore/:type/:key', component: ExploreComponent },
  { path: 'explore/:type/:key', component: ExploreComponent },
  { path: 'cart', component: CartComponent },
  { path: 'privacy', component: PrivacypolicydetailsComponent },
  { path: 'terms', component: TermsofserviceComponent },
  { path: 'support', component: CustomersupportComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    Title
  ],
})
export class AppRoutingModule { }
