import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpParameterCodec } from "@angular/common/http";
import { WebinarapiService } from '../../../services/webinarapi.Service';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.css']
})

export class HomeBannerComponent implements OnInit {
  public searchKey: string;
  public loading = true;
  public items = [];

  constructor(
    private apiService: WebinarapiService, 
    private route: ActivatedRoute,
    private router:Router,
    @Inject(PLATFORM_ID) private platformId: Object,) { }

  ngOnInit() {
    var dataset = [];
    if (isPlatformBrowser(this.platformId)) {
      this.apiService.getAllIndustries(dataset)
        .subscribe( 
          
          data => {

            if(data['0'].result != undefined)
            {
              const mappedArr = Object.keys(data['0'].result).map(
                key => (data['0'].result[key])
                );
              this.items['result'] = mappedArr;
            }
            this.loading = false;
          }
        
          ); 
        }
  }

  
  onSubmit(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.searchKey = event.target.searchKey.value;
      if(this.searchKey !='')
      {
        this.router.navigate(['search/'+this.searchKey]);
      }

      return false;
    }
  }

}
