<app-header></app-header>
<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Login</h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-info-1x course-info-2x p-3">

            <div class="row">

                <div class="col-md-12">
                    <h3>Login
                    </h3>
                </div>

            </div>

        </div>
        <div class="course-details-1x p-3 mb-5">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" >
            <div class="row">
                
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Email Address</label>
                        <input type="email"  formControlName="email" class="form-control">
                    </div>
                    <div *ngIf="loginForm.get('email')?.errors &&
                    loginForm.get('email')?.hasError('email') && (loginForm.touched || loginForm.dirty)">
                        <span class="text-danger">Please enter a valid email</span> 
                      </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="text" formControlName="upass" class="form-control">
                    </div>
                    <div *ngIf="loginForm.get('upass')?.errors &&
                    loginForm.get('upass')?.hasError('upass') && (loginForm.touched || loginForm.dirty)">
                        <span class="text-danger">Please enter your password</span> 
                      </div>

                    <div *ngIf="errorMessage">
                        <span class="text-danger">{{errorMessage}}</span> 
                    </div>

                    <button class="btn btn-primary btn-small btn-block text-center mt-2" disabled="{{buttonDisabled}}" type="submit">Login</button>
                    <div class="text-center" >
                      <img  *ngIf="processing" src="assets/img/loading.gif" /> 
                    </div>
                   
                </div>
            


            </div>
        </form>

        </div>
        
        
    </div>
</div>

<app-footer></app-footer>
