<app-header></app-header>
<div class="page-banner">
   <div class="hvrbox">
       <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
       <div class="hvrbox-layer_top">
           <div class="container">
               <div class="overlay-text text-center">
                   <h2 class="font-weight-bold">Payment Method
                   </h2>
               </div>
           </div>
       </div>
   </div>
</div>

<div class="course-header-1x">
   <div class="container">
       <div class="course-details-1x">
        

           <div class="row" *ngIf="cartData['totalAmount'] > 0">
               <div class="col-md-8">
                  <div class="row text-success" *ngIf="paymentDoneFlag ===1">
                     <h2>Payment Successful</h2>
                     {{successMessage}}
                  </div>

                  <div class="row text-success" *ngIf="successMessageInvoice">
                    <h2>Invoice created successfully</h2>
                    {{successMessageInvoice}}

                    <span><strong>Note:</strong> You will get invoice details on your email from stripe.
                        Please pay the invoice.
                       Our team will comunicate with you after successful payment. </span>
                  </div>
                  
                   <div class="row" *ngIf="paymentDoneFlag ===0 && !successMessageInvoice">
                       
                       <div class="col-md-6" *ngIf="paymentDoneFlag ===4">
                        <div class="card p-4 text-center">
                            <h5>Pay Using
                            <a href="#">
                                <img src="assets/imgs/stripe.png" style="width: 50px;">
                            </a></h5>
                            <p *ngIf="paymentDoneFlag !==1" class="border-bottom-0">
                                <b>We accept all major credit cards</b>
                                <img src="assets/imgs/card.png">
    
                               </p>
                        </div>
                       <div>Please fill out all <strong>required fields starting with <label class="control-label"></label></strong></div>
                        <form [formGroup]="userForm" *ngIf="userForm" (ngSubmit)="onSubmit()" >
                           <div class="form-group required">
                              <label class="control-label">Name</label>
                              <input type="text" class="form-control" minlength="2" formControlName="cname">
                              <div *ngIf="userForm.get('cname').invalid && (userForm.get('cname').dirty || userForm.get('cname').touched)">
                                <span class="text-danger">Please enter your name</span> 
                              </div>

                             
                            </div>
                           <div class="form-group required">
                              <label class="control-label">Card Number</label>
                               <input type="text" ng-trim="true" (change)="trimCard()" formControlName="cardNumber" class="form-control" minlength="14" maxlength="16">
                               <div *ngIf="userForm.get('cardNumber').invalid && (userForm.get('cardNumber').dirty || userForm.get('cardNumber').touched)">
                                <span class="text-danger">Please enter valid card number</span> 
                              </div>
                            </div>
                            
                            <div class="row">
                              <div class="col-md-4 col-sm-4 ">
                                <label class="control-label">Exp Month </label>
                                 <select formControlName="expMonth" class="form-control">
                                    <option disabled selected value="">Select</option>
                                    <option  *ngFor="let number of monthRange" value="{{number}}">{{number}}</option>
                                 </select>
                              </div>
                              <div class="col-md-4 col-sm-4" >
                                <label class="control-label">Exp Year</label>
                                 <select formControlName="expYear" class="form-control">
                                    <option disabled selected value="">Select</option>
                                    <option  *ngFor="let number of yearRange" value="{{number}}">{{number}}</option>
                                 </select>
                              </div>
                              <div class="form-group col-md-2 col-sm-2" ><label class="control-label">CVC</label>
                                 <input type="text" style="width: 80px;" class="form-control" formControlName="cvc" id="cvc" minlength="3" maxlength="4"></div>
                              <div class="col-md-12 col-sm-12 form-group">
                                 <div *ngIf="userForm.get('cvc').invalid && (userForm.get('cvc').dirty || userForm.get('cvc').touched)">
                                    <span class="text-danger">Please enter valid cvc</span> 
                                 </div>
                                 <div *ngIf="userForm.get('expMonth').invalid && (userForm.get('expMonth').dirty || userForm.get('expMonth').touched)">
                                    <span class="text-danger">Please select expiry month in between 01 to 12</span> 
                                 </div>
                                 <div *ngIf="userForm.get('expYear').invalid && (userForm.get('expYear').dirty || userForm.get('expYear').touched)">
                                    <span class="text-danger">Please select expiry year</span> 
                                 </div>
                              </div>
                              
                                
                                <div class="col-md-12 col-sm-12 form-group">
                                    <label class="control-label">Address</label>
                                    <input type="text" class="form-control" required formControlName="address1" id="address1">
                                    <div class="col-md-12 col-sm-12">
                                        <div *ngIf="userForm.get('address1').invalid && (userForm.get('address1').dirty || userForm.get('address1').touched)">
                                            <span class="text-danger">Please enter valid Address</span> 
                                         </div>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-12 form-group">
                                    <label >Address Line 2</label>
                                    <input type="text" class="form-control"  formControlName="address2" id="address2">
                                    <div class="col-md-12 col-sm-12">
                                        
                                    </div>
                                </div>
                                
                                
                                
                                <div class="col-md-6 col-sm-6"> 
                                    <div class="form-group">
                                        <label  class="control-label" for="city">City</label>
                                    <input type="text" class="form-control" formControlName="city" id="city"> 
                                    <div class="col-md-12 col-sm-12">
                                        <div *ngIf="userForm.get('city').invalid && (userForm.get('city').dirty || userForm.get('city').touched)">
                                            <span class="text-danger">Please enter valid City</span> 
                                         </div>
                                    </div>
                                </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="form-group">
                                    <label  class="control-label" for="zip">Zip</label>
                                    <input type="text" class="form-control" formControlName="zip" id="zip"> 
                                    <div class="col-md-12 col-sm-12">
                                        <div *ngIf="userForm.get('zip').invalid && (userForm.get('zip').dirty || userForm.get('zip').touched)">
                                            <span class="text-danger">Please enter valid Zip Code</span> 
                                         </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12">
                                    <div class="form-group">
                                    <label  class="control-label" for="country">Country</label>
                                    <select formControlName="country" class="form-control" id="country">
                                    <option disabled selected value="">Select</option>
                                    <option  *ngFor="let item of countryRange| keyvalue" value="{{item.key}}">{{item.value}}</option>
                                    </select>
                                    </div>
                                </div>
                              
                              <div class="col-md-12 col-sm-12 grand-btn ">
                                 <div class="" >
                                    <img  *ngIf="processing" src="assets/img/loading.gif" /> 
                                 </div>
                                 <div *ngIf="errorMessage">
                                    <span class="text-danger">{{errorMessage}}</span> 
                                 </div>
                                 <button 
                                     class="btn btn--black btn--full text-center btn--lg"  type="submit">Proceed to
                                     Payment</button>
                                 
                             </div>
                           </div>
                           </form>

                           
                       </div>

                       <!--Billing Details-->
                       <div class="col-md-6">
                        <div class="card p-4 text-center">
                            <h5>Pay Using
                            <a href="#">
                                <img src="assets/imgs/paypal.png" style="width: 50px;">
                            </a></h5>
                            <p *ngIf="paymentDoneFlag !==1" class="border-bottom-0">
                                <b>We accept all major cards and paypal account payment</b>
                                
    
                               </p>
                        </div>
                        <br/>
                           <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                       </div>
                       
                       
                       <!-- Invoice Creation -->
                       

                       <div class="col-md-6" *ngIf="!successMessageInvoice && stripeInvoiceFlag === 1">
                            <div class="card p-4 text-center">
                                <h5 >Request Direct Invoice 
                                    <a href="#">
                                
                            </a></h5>
                                
                            </div>

                            <form [formGroup]="userInvoiceForm" *ngIf="userInvoiceForm" (ngSubmit)="onDirectSubmit()" >
                                <div class="form-group">
                                   <label>Name</label>
                                   <input type="text" class="form-control" minlength="2" formControlName="invoicename">
                                   <div *ngIf="userInvoiceForm.get('invoicename')?.errors &&
                                   userInvoiceForm.get('invoicename')?.hasError('invoicename') && (userInvoiceForm.touched || userInvoiceForm.dirty)">
                                     <span class="text-danger">Please enter your name</span> 
                                   </div>
                                 </div>
                                 <span><strong>Note:</strong> You will get invoice details on your email from stripe.
                                     Please pay the invoice.
                                    Our team will comunicate with you after successful payment. </span>
                                     <p>Please note your order <b> #{{cartData['result'][0]['cart_id']}}</b> for future reference.
                                     </p>
                                     <div class="" >
                                        <img  *ngIf="processingInvoice" src="assets/img/loading.gif" /> 
                                     </div>
                                     <div *ngIf="errorMessageInvoice">
                                        <span class="text-danger">{{errorMessageInvoice}}</span> 
                                     </div>
                                    <button class="btn btn--black btn--full text-center btn--lg"  type="submit">
                                        Get Invoice
                                    </button>
                                     
                            </form>
                        </div>
                    
                       <!--<div class="col-md-6">
                           <div class="card p-4 text-center">
                               <h4 class="mb-3"> Pay Using</h4>
                               <p class="mb-2">Guest checkout on</p>
                               <a href="#">
                                   <img src="assets/imgs/paypal.png" style="width: 200px;">
                               </a>
                           </div>
                       </div>-->
                   </div>
               </div>
               <div class="col-md-4">
                   <div class="course-details-sidebar">

                       <div class="course-feature" *ngIf="cartData['result']">

                           <h2><a routerLink="/cart" *ngIf="paymentDoneFlag ===0" title="Edit Order" class="float-right"><i class="fas fa-pencil-alt"></i></a> Your
                               order 
                           </h2>

                           <ul >
                              <li><b>Order:</b> #{{cartData['result'][0]['cart_id']}}</li>
                              <li><b>Email:</b> {{cartData['result'][0]['email']}}</li>
                              <li><b>Contact:</b> {{cartData['result'][0]['contact']}}</li>
                              <li *ngIf="paymentDoneFlag ===1"><b>Payment:</b>Successful</li>
                              
                               <li>
                                   <table class="table order-table">
                                       <thead>
                                           <tr>
                                               <th>Products</th>
                                               <th>Subtotal</th>
                                           </tr>
                                       </thead>
                                       <tbody>
                                           <tr *ngFor="let singleCart of cartData['result']; let i = index">
                                               <td>{{i+1}}. {{singleCart['item_details']['title']}}</td>
                                               
                                                <td><div *ngFor="let singleprice of singleCart['pricelist']">
                                                   <span class="" style="white-space: nowrap;" *ngIf="singleprice['selected'] ===1">${{singleprice['amount']}}
                                                      <b  data-toggle="tooltip" data-placement="left" title="{{singleprice['name']}}"><i class="fas fa-info-circle"></i></b>
                                                   </span>
                                                </div>
                                               </td>
                                           </tr>
                                           
                                       </tbody>
                                   </table>
                               </li>

                               <li><b>Total</b> <span class="text-info">
                                       <b>${{cartData['totalAmount']}}</b>
                                   </span>
                               </li>
                               <!--
                               <li>
                                   <b>Disclaimer -</b>
                                   <p>All Cards except.....</p>
                               </li>
                               -->
                               
                               <li>
                               <a class="" target="_blank" routerLink="/refund"> See our Refund Policies</a>
                               </li>

                           </ul>


                       </div>

                       <div class="footer-social-link d-none">

                           <h2>Share via</h2>

                           <ul>

                               <li><a href="#"> <i class="fab fa-facebook-f"></i> </a></li>

                               <li><a href="#"> <i class="fab fa-instagram"></i> </a></li>

                               <li><a href="#"> <i class="fab fa-twitter"></i> </a></li>

                               <li><a href="#"> <i class="fab fa-google-plus-g"></i> </a></li>

                           </ul>

                       </div>



                   </div>
               </div>
           </div>

           <div class="row" *ngIf="!cartData['totalAmount']">
            <div class="col-lg-12 text-center">
                <h4>Oops Your cart is empty!</h4>
                 <br>
                <h6>Looks like you haven't added anything to your cart yet.</h6>
                <br>
                <a routerLink="/" class="btn btn--lg btn--black">
                 Shop now </a>
            </div>
            </div>
       </div>
   </div>
</div>
<style>
      .control-label:after {content:"*";color:red;}
</style>
<app-footer></app-footer>