import { BrowserModule,DomSanitizer,provideClientHydration  } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CookieModule } from 'ngx-cookie';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/layout/header/header.component';
import { FooterComponent } from './component/layout/footer/footer.component';
import { ContactComponent } from './component/pages/contact/contact.component';
import { HomeComponent } from './component/pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { BannerComponent } from './component/layout/banner/banner.component';
import { HomeBannerComponent } from './component/layout/home-banner/home-banner.component';
import { HomeWebinarComponent } from './component/segments/home-webinar/home-webinar.component';
import { HomeSeminarComponent } from './component/segments/home-seminar/home-seminar.component';
import { HomeVideoComponent } from './component/segments/home-video/home-video.component';
import { HomeContactComponent } from './component/segments/home-contact/home-contact.component';
import { WebinarComponent } from './component/pages/webinar/webinar.component';
import { SeminarComponent } from './component/pages/seminar/seminar.component';
import { WebinarDetailsComponent } from './component/pages/webinar-details/webinar-details.component';

import { removeHtmlTagPipe,stripHtmlTagPipe } from './pipes/custom.pipe';
import { IndustriesComponent } from './component/pages/industries/industries.component';
import { IndustrydetailsComponent } from './component/pages/industrydetails/industrydetails.component';
import { RefundComponent } from './component/pages/refund/refund.component';

//import { SeminarDetailsComponent } from './component/pages/seminar-details/seminar-details.component';
import { WebinarapiService } from './services/webinarapi.Service';
import { PaymentComponent } from './component/pages/payment/payment.component';
import { SearchComponent } from './component/pages/search/search.component';
import { ServiceComponent } from './component/pages/service/service.component';
import { LoginComponent } from './component/pages/login/login.component';
import { DashboardComponent } from './component/authpages/dashboard/dashboard.component';
import { AuthheaderComponent } from './component/authpages/authheader/authheader.component';
import { ExploreComponent } from './component/pages/explore/explore.component';
import { CartComponent } from './component/pages/cart/cart.component';
import { NgxPayPalModule } from 'ngx-paypal';
import {CookieService} from 'ngx-cookie-service';

import { PrivacypolicydetailsComponent } from './component/pages/privacypolicydetails/privacypolicydetails.component';
import { TermsofserviceComponent } from './component/pages/termsofservice/termsofservice.component';
import { CustomersupportComponent } from './component/pages/customersupport/customersupport.component';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'top-right',
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#02b3e4'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    HomeComponent,
    BannerComponent,
    HomeBannerComponent,
    HomeWebinarComponent,
    HomeSeminarComponent,
    HomeVideoComponent,
    HomeContactComponent,
    WebinarComponent,
    SeminarComponent,
    removeHtmlTagPipe,
    stripHtmlTagPipe,
    WebinarDetailsComponent,
    IndustriesComponent,
    IndustrydetailsComponent,
    RefundComponent,
    PaymentComponent,
    SearchComponent,
    ServiceComponent,
    LoginComponent,
    DashboardComponent,
    AuthheaderComponent,
    ExploreComponent,
    CartComponent,
    PrivacypolicydetailsComponent,
    TermsofserviceComponent,
    CustomersupportComponent
    //SeminarDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPayPalModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [WebinarapiService,CookieService,provideClientHydration()],
  bootstrap: [AppComponent]
})
export class AppModule { }
