import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ReactiveFormsModule,FormBuilder,FormGroup,FormControl,Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  domainListData: any[] = [];
  //public contactForm;
  public processing:boolean = false;
  public showContactMessage:boolean = false;
  public buttonDisabled;
  public errorMessage;
  
  public contactForm: FormGroup = new FormGroup({
    contactName: new FormControl(''),
    email: new FormControl(''),
    subject: new FormControl(''),
    message: new FormControl(''),
  });
  constructor(private cgapiService: WebinarapiService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getDomainDetails()
        .subscribe( data => {
          if(data['0'].result.title != undefined)
          {
              this.domainListData['title'] = data['0'].result.title;
          }
          if(data['0'].result.contact != undefined)
          {
              this.domainListData['contact'] = data['0'].result.contact;
          }
          if(data['0'].result.email != undefined)
          {
              this.domainListData['email'] = data['0'].result.email;
          }
          if(data['0'].result.address1 != undefined)
          { 
              this.domainListData['address1'] = data['0'].result.address1;
          }
          if(data['0'].result.address2 != undefined)
          {
              this.domainListData['address2'] = data['0'].result.address2;
          }
        }); 

        this.formBuilder = new FormBuilder();
        this.contactForm = this.formBuilder.group({
          contactName: [null, [Validators.required]],
          email: [null, [Validators.required, Validators.email]],
          subject: [null, [Validators.required]],
          message: [null, [Validators.required]],
        });
      }
  }

  onSubmit(){
    if (isPlatformBrowser(this.platformId)) {
    if(this.contactForm.valid){
      this.processing = true;
      
     var expiryYear = '20'+this.contactForm.value.expYear;
     var dataset =
      {
        'contactName': this.contactForm.value.contactName,
        'email':this.contactForm.value.email,
        'subject':this.contactForm.value.subject,
        'message':this.contactForm.value.message,
      };
      
     /*
      var expiryYear = '20'+this.contactForm.get('expYear');
      var dataset =
      {
        'contactName': this.contactForm.get('contactName'),
        'email':this.contactForm.get('email'),
        'subject':this.contactForm.get('subject'),
        'message':this.contactForm.get('message'),
      };
      */
     
      this.cgapiService.postContact(dataset)
      .subscribe(data => {
        
        if(data['0'].result != undefined  && data['0'].result != '')
        {
            this.showContactMessage = true;
           this.buttonDisabled = false;
           this.processing = false;
           this.errorMessage ='Thank you for contacting us. We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Please come back and see us often.';
        }
        else
        {
           this.buttonDisabled = true;
           this.processing = false;
           this.errorMessage ='Please try again.'
        }
       }); 
    
     
   } else {
    this.errorMessage ='Please Enter all details !';
    
   }
  }
 }

  
}
