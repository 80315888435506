
import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';
import { ReactiveFormsModule,FormBuilder,FormGroup,FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})

export class SearchComponent implements OnInit {

  public webinarDetailsData = [];
  public currentDate = new Date();
  public webinarDate;
  //public searchForm;
  public finalPrice;
  public errorMessage;
  public priceDetails = [];
  private webinarId;
  private buyFree = 0;
  private buttonDisabled;
  public searchKey;
  public noResult;

  public searchForm: FormGroup = new FormGroup({
    price: new FormControl(''),
 });
  
  constructor(private cgapiService: WebinarapiService ,private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object) { }
    public loading:boolean = false;
    public processing:boolean = false;
    public webinarListData = [];

    
  ngOnInit() {
    this.loading = true;
    if (isPlatformBrowser(this.platformId)) {
      this.route.params.subscribe(val => {
        this.searchKey = val.key;
        this.keySearch();
      });
      this.searchKey = this.route.snapshot.paramMap.get('key');
      this.keySearch();
    }

  }
  keySearch()
  {
    if (isPlatformBrowser(this.platformId)) {
    this.loading = true;
    var dataset =
    {
      'search_key' : this.searchKey
    };
    this.cgapiService.postKeySearch(dataset)
      .subscribe( 
        data => {

          if(data['0'].result != undefined)
          {
            const mappedArr = Object.keys(data['0'].result).map(
              key => (data['0'].result[key])
              );
            this.webinarListData['result'] = mappedArr;
          }
          else
          {
            this.noResult = 1
          }
          this.loading = false;
        }
       
        ); 
      }
  }

}
