<div class="main-menu-1x">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="main-menu">
            <nav class="navbar navbar-expand-lg navbar-light bg-light btco-hover-menu"> 
              <a class="navbar-brand"  *ngIf="domainListData"
              routerLink="/" > <b> {{domainListData.title}} </b> 
              </a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span
                  class="navbar-toggler-icon"></span> </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
                <ul class="navbar-nav ml-auto main-menu-nav">
                  <li class="nav-item"><a class="dropdown-item" >Welcome</a></li>
                  
                  <li class="nav-item">
                    <a class="dropdown-item" routerLink="/login" routerLinkActive="active">Logout</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  