import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';

@Component({
  selector: 'app-home-seminar',
  templateUrl: './home-seminar.component.html',
  styleUrls: ['./home-seminar.component.css']
})
export class HomeSeminarComponent implements OnInit {
  public homeSeminarListData = [];
  constructor(private cgapiService: WebinarapiService,
    @Inject(PLATFORM_ID) private platformId: Object ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getHomeSeminarList()
        .subscribe( data => 
          {
            if(data['0'].result != undefined)
            {
              const mappedArr = Object.keys(data['0'].result).map(
                key => (data['0'].result[key])
                );
              this.homeSeminarListData['result'] = mappedArr;
            }
          }
          
          ); 
      //.subscribe( data =>console.log(data[0])); 
    }
  }

}
