import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';
import { ReactiveFormsModule,FormBuilder,FormControl,FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-webinar-details',
  templateUrl: './webinar-details.component.html',
  styleUrls: ['./webinar-details.component.css']
})
export class WebinarDetailsComponent implements OnInit {

  public webinarDetailsData = [];
  public currentDate = new Date();
  public webinarDate;
  //public bookingForm;
  public finalPrice;
  public errorMessage;
  public priceDetails = [];
  private webinarId;
  private buyFree = 0;
  private buttonDisabled;

  public bookingForm: FormGroup = new FormGroup({
      price: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      reference: new FormControl(''),
      is_shearin: new FormControl(''),
   });

  constructor(private cgapiService: WebinarapiService ,private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Object ) { }
    public loading:boolean = false;
    public processing:boolean = false;
    public webinarNotfoundFlag:boolean = false;
    
  ngOnInit() {
    
    if (isPlatformBrowser(this.platformId)) {
      this.loading = true;
      let id = this.route.snapshot.paramMap.get('id');
      this.webinarId = id;
      this.cgapiService.getWebinarDetails(id)
      .subscribe(data => {
         //Data Preparation
         if(data['0'].status == 400)
         {
            this.webinarNotfoundFlag = true;
         }

         if(data['0'].result.title != undefined)
         {
            this.webinarDetailsData['title'] = data['0'].result['title'];
         }
         if(data['0'].result['description'] != undefined)
         {
            this.webinarDetailsData['description'] = data['0'].result['description'];
         }
         if(data['0'].result.price != undefined)
         {
            this.webinarDetailsData['price'] = data['0'].result['price'];
            this.webinarDetailsData['del_price'] = data['0'].result['price']+100;
         }

         if(data['0'].result['price_list'] != undefined)
         {
            this.webinarDetailsData['priceArr'] = data['0'].result['price_list'];
         }

         if(data['0'].result['duration'] != undefined)
         {
            this.webinarDetailsData['duration'] = data['0'].result['duration'];
         }
         if(data['0'].result['start_date'] != undefined)
         {
            this.webinarDetailsData['start_date'] = data['0'].result['start_date'];
            this.webinarDate = new Date(data['0'].result['start_date']);
         }
         if(data['0'].result['start_time'] != undefined)
         {
            this.webinarDetailsData['start_time'] = data['0'].result['start_time'];
         }
         
         if(data['0'].result.upcoming_flag)
         {
            this.webinarDetailsData['upcomingFlag'] = 1;
         }

         if(data['0'].result['freeFlag'] != undefined && data['0'].result['freeFlag'] == 1)
         {
            this.buyFree = 1;
         }
         else
         {
            this.buyFree = 0;
         }

         if(data['0'].result['freeFlag'] != undefined && data['0'].result['freeFlag'] == 1)
         {
            this.buyFree = 1;
         }
         else
         {
            this.buyFree = 0;
         }
         if(data['0'].result['price1'] != undefined)
         {
            this.webinarDetailsData['price1'] = data['0'].result['price1'];
         }
         else
         {
         this.webinarDetailsData['price1'] = 0;
         }
         if(data['0'].result.price2 != undefined)
         {
            this.webinarDetailsData['price2'] = data['0'].result['price2'];
         }
         else
         {
         this.webinarDetailsData['price2'] = 0;
         }

         if(data['0'].result['cd_price'] != undefined)
         {
            this.webinarDetailsData['pricec'] = data['0'].result['cd_price'];
         }
         else
         {
         this.webinarDetailsData['pricec'] = 0;
         }

         if(data['0'].result['recorded_price'] != undefined)
         {
            this.webinarDetailsData['pricer'] = data['0'].result['recorded_price'];
         }
         else
         {
         this.webinarDetailsData['pricer'] = 0;
         }

         this.finalPrice = 0;
         this.loading = false;

      })
   
         //.subscribe( data => this.webinarDetailsData = data['0']); 

         //.subscribe( data =>console.log(data[0])); 
         if (isPlatformServer(this.platformId)) {
            this.formBuilder = new FormBuilder();
            this.bookingForm = this.formBuilder.group({
               price: [null, [Validators.required]],
               phone: [null, [Validators.required]],
               email: [null, [Validators.required, Validators.email]],
               reference: [null, [Validators.required]],
               is_shearin:[null]
               /*cardNumber: ['', [Validators.required]],
               expMonth: ['', [Validators.required]],
               expYear: ['', [Validators.required]],
               cvc: ['', [Validators.required]]
               */
            });
         }
      }
  }

  onSubmit(){
      console.log('in1');
   if (isPlatformBrowser(this.platformId)) {
      console.log('in2');
      // console.log(this.bookingForm.get('is_shearin'));
      // console.log(this.bookingForm.value.is_shearin);
      // //if(this.bookingForm.value.is_shearin != null)
      // if(this.bookingForm.get('is_shearin') != null)
      // {
      //    this.errorMessage = 'Please give valid input';
      //    return false;
      // }
      // else 
      
      if(this.buyFree !=1 && this.finalPrice < 1)
      {
         this.errorMessage = 'Please add some item';
         return false;
      }
      if(this.buyFree !=1 && this.finalPrice < 1)
      {
         this.errorMessage = 'Please add some item';
         return false;
      }
      else if(this.bookingForm.valid){
         this.buttonDisabled = false;
         this.processing = true;
         //Order Post Preparation
         /*var dataset =[];
         dataset['id'] = this.webinarId;
         dataset['totalPrice'] = this.finalPrice;
         dataset['totalPriceArr'] =this.priceDetails;
         dataset['email'] =this.bookingForm.value.email;
         dataset['contact'] =this.bookingForm.value.phone;
         */
      var expiryYear = '20'+this.bookingForm.value.expYear;
         
      var dataset =
         {
         'id' : this.webinarId,
         'totalPrice': this.finalPrice,
         'totalPriceArr':this.priceDetails,
         'email':this.bookingForm.value.email,
         'contact':this.bookingForm.value.phone,
         'website':this.bookingForm.value.website,
         'reference':this.bookingForm.value.reference,
         'cookie':this.cookieService.get('webinar_ses')
         };
         
         
         
         /*var dataset =
         {
         'id' : this.webinarId,
         'totalPrice': this.finalPrice,
         'totalPriceArr':this.priceDetails,
         'email':this.bookingForm.get('email'),
         'contact':this.bookingForm.get('phone'),
         'website':this.bookingForm.get('website'),
         'reference':this.bookingForm.get('reference'),
         'cookie':this.cookieService.get('webinar_ses')
         };
         */
         this.cgapiService.cartOrder(dataset)
         .subscribe(data => {

            //Data Preparation
            if(data['0'].result['orderId'] != '' )
            {
               
               this.cookieService.set('webinar_ses', data['0'].result['orderId'])
               this.cgapiService.getDomainCartData();
               
               //console.log('success');
               //this.router.navigate([`${pageName}`]);
               this.router.navigate(['cart']);

               /*
               if(data['0'].result['paySuccess'] != undefined  && data['0'].result['paySuccess'] != '')
               {
                  this.buttonDisabled = false;
                  this.processing = false;
                  this.errorMessage ='Payment has been successfull for order #'+data["0"].result['orderId']+'. Our team will get to you shortly';
               }
               else
               {
                  this.buttonDisabled = true;
                  this.processing = false;
                  this.errorMessage ='Payment has not been successful.Please try again with valid card details.'
               }
               //this.errorMessage ='Please pay via paypal to colplete the order #'+data['0'].result.orderId+'.';
            //window.open(data['0'].result.paypalLink, "_blank");
            */
            }
            else if(data['0'].result['orderId'] != '' && data['0'].result['freeFlag'] != undefined 
            && data['0'].result['freeFlag'] == 1 && data['0'].result['successLink'] != undefined )
            {
               window.open(data['0'].result['successLink']);
            }
            else
            {
               this.buttonDisabled = true;
            this.processing = false;
            this.errorMessage ='Oops Server is too busy!! Please try after sometime';
            }
         }); 
      
      
      } else {

      this.errorMessage ='Please Enter Email, Contact Number and reference';
      }
   }
 }

  

 checkValue(price:number, isChecked: boolean,checkId:number) {
   if (isPlatformBrowser(this.platformId)) {
      if(isChecked) 
      {
      this.priceDetails.push(checkId);
      this.finalPrice = this.finalPrice + price ;
      }
      else
      {
      var arrindex = this.priceDetails.indexOf(checkId);
      if (arrindex > -1) {
         this.priceDetails.splice(arrindex, 1);
      }
      this.finalPrice = this.finalPrice - price ;
      }
      
   /*const emailFormArray = this.bookingForm.controls.price;

   if(isChecked) {
      emailFormArray.push(price);
   } else {
      let index = emailFormArray.controls.findIndex(x => x.value == price)
      emailFormArray.removeAt(index);
   }
   */
   }
}

   scrollTo(uniqueID) {
      //console.log(uniqueID);

         //var elmnt = document.getElementById(uniqueID); // let if use typescript
         //elmnt.scrollIntoView(); // this will scroll elem to the top
         //window.scrollTo(0, 0);
      }
}
