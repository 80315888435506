import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  public domainListData = [];
  constructor(private cgapiService: WebinarapiService ,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit() {
    //this.titleService.setTitle( window.location.hostname );
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getDomainDetails()
        .subscribe( data =>
          {
            console.log(data['0'].result.cartcount);
            //this.titleService.setTitle( data['0'].result.title );
            //this.domainListData = JSON.parse(data['0'].result);
            if(data['0'].result.title != undefined)
            {
                this.domainListData['title'] = data['0'].result.title;
            }

            if(data['0'].result.email != undefined)
            {
                this.domainListData['email'] = data['0'].result.email;
            }

            if(data['0'].result.cartcount > 0)
            {
              this.domainListData['cartcount'] = data['0'].result.cartcount;
            }
            else
            {
              this.domainListData['cartcount'] = 0;
            }
        });
    }
  }

}
