import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';
import { ReactiveFormsModule,FormBuilder,FormGroup,FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  //public loginForm;
  public errorMessage;
  public buttonDisabled;
  public loading:boolean = false;
  public processing:boolean = false;

  public loginForm: FormGroup = new FormGroup({
    upass: new FormControl(''),
    email: new FormControl(''),
  });

  constructor(private cgapiService: WebinarapiService ,private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {

      this.formBuilder = new FormBuilder();
      this.loginForm = this.formBuilder.group({
        upass: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
      });
    }
  }

  onSubmit(){
    if (isPlatformBrowser(this.platformId)) {
      if(this.loginForm.valid){
        this.buttonDisabled = false;
        this.processing = true;

        /*var dataset = {
          'uname':this.loginForm.value.email,
          'pass':this.loginForm.value.upass,
        };*/

        var dataset = {
          'uname':this.loginForm.get('email'),
          'pass':this.loginForm.get('upass'),
        };
        

        this.cgapiService.postLogin(dataset)
        .subscribe({
          next: this.handleUpdateResponse.bind(this),
          error: this.handleError.bind(this)
      });
      } else {
        
      }
    }
  }

  handleUpdateResponse(data){
    if (isPlatformBrowser(this.platformId)) {
      if(data[0]['status'] == 200)
      {
        var logToken = localStorage.setItem('isLoggedIn','1');
        this.router.navigate(['/service'])
      }
    }
  }

  handleError(data){
    console.log(data);
  }
  

}
