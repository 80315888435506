<app-header></app-header>

<div class="page-banner">
  <div class="hvrbox">
    <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
    <div class="hvrbox-layer_top">
      <div class="container">
        <div class="overlay-text text-center" *ngIf="webinarDetailsData">
          <h3 class="font-weight-bold" [innerHTML]= webinarDetailsData.title></h3>

          <h3 class="font-weight-bold" *ngIf="webinarNotfoundFlag"> No item found</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="course-header-1x" *ngIf="!webinarNotfoundFlag">
  <div class="container" *ngIf="webinarDetailsData">
    <div class="course-info-1x course-info-2x">

      <div class="row">
        <div class="col-md-12 text-center" >
          <img  *ngIf="loading" src="assets/img/loading.gif" /> 
        </div>
        <div class="col-md-5 border-right">



          <div class="media">

            <div class="media-body px-4">

              <h4 class="mt-0">Webinar</h4>

              <p [innerHTML]= webinarDetailsData.title></p>

            </div>

          </div>



        </div>

        <div class="col-md-4 border-right">



          <div class="media">

            <div class="media-body d-flex">
              <div>
                <div *ngIf="webinarDetailsData.upcomingFlag">
                  <h4 class="mt-0">Course Date & Time</h4>
                  <p>{{ webinarDetailsData.start_date|date }} - {{ webinarDetailsData.start_time }} EST</p>
                </div>
                
              </div>
              <div class="ml-4">
                <h4 class="mt-0">Course Duration</h4>
                <p *ngIf="webinarDetailsData.duration">{{ webinarDetailsData.duration }}</p>
              </div>

            </div>

          </div>



        </div>

        <div class="col-md-2 d-none">

          <div class="course-info-left">

            <div class="media">

              <div class="media-body">

                <h4>Course Duration</h4>

                <p>80 Minutes</p>

              </div>

            </div>

          </div>

        </div>

        <div class="col-md-3">

          <div class="course-info-right">

            <h3><del>${{webinarDetailsData.del_price}}</del>${{webinarDetailsData.price}}</h3>

            <button  class="btn btn-small" (click)="scrollTo('inemail')">Buy Now</button>

          </div>

        </div>



      </div>

    </div>
    <div class="course-details-1x">

      <div class="row">

        <div class="col-md-8">

          <div class="course-details-left">


            <div class="course-menu-nav">

              <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">

                <li class="nav-item">

                  <a class="nav-link active" id="overview-tab" data-toggle="tab" href="#overview"
                    role="tab" aria-controls="overview" aria-selected="true"> <i
                      class="far fa-file-alt"></i> Overview</a>

                </li>

                <!--
                <li class="nav-item">

                  <a class="nav-link" id="instructor-tab" data-toggle="tab" href="#instructor"
                    role="tab" aria-controls="instructor" aria-selected="false"><i
                      class="far fa-user"></i> Speaker</a>

                </li>
                -->

              </ul>

              <div class="tab-content course-menu-tab" id="myTabContent">

                <div class="tab-pane fade show active" id="overview" role="tabpanel"
                  aria-labelledby="overview-tab">

                  <div class="learning-system">

                    <h4>Description</h4>

                    <p [innerHTML]= webinarDetailsData.description ></p>


                  </div>




                </div>



                <div class="tab-pane fade" id="instructor" role="tabpanel"
                  aria-labelledby="instructor-tab">

                  <div class="blog-author instructor-profile">

                    <div class="media">

                      <!--<img src="assets/imgs/testimonial-1.jpg"
                        alt="Generic placeholder image">-->

                      <div class="media-body">

                        <h5>Please check the description</h5>

                        <p></p>



                      </div>

                    </div>

                  </div>

                </div>



              </div>

            </div>



          </div>

        </div>

        <div class="col-md-4">

          <div class="course-details-sidebar">

            <div class="course-feature">

              <h2 id="inemail">Booking</h2>
              <form [formGroup]="bookingForm" *ngIf="bookingForm" (ngSubmit)="onSubmit()" >
                <p class="mx-4" *ngIf="webinarDetailsData.upcomingFlag===1"><b>Attend Live Webinar :</b> </p>
              <ul>

                <li *ngFor="let singleprice of webinarDetailsData.priceArr">
                  
                  
                  <div class="row course-single-row" *ngIf="webinarDetailsData.upcomingFlag===1 && singleprice['is_live'] ===1">
                   
                    <div class="col-sm-2 col-2">
                      <input type="checkbox" formControlName="price" (change)="checkValue(singleprice['amount'],$event.target.checked,singleprice['id'])">
                    </div>
                    <div class="col-sm-7 col-7" style="line-height: 22px">
                      <p class="pt-2">
                        {{singleprice['name']}}
                      </p>
                      <!--<small><a href="#" class="text-info" data-toggle="modal"
                          data-target="#exampleModal"><em>Learn More</em></a></small>-->
                    </div>
                    <div class="col-sm-3 col-3 text-right">
                      ${{singleprice['amount']}}
                    </div>
                  </div>
                </li>
              </ul>
              <p class="mx-4"><b>Recorded Version :</b> </p>
              <ul>
                <li *ngFor="let singleprice of webinarDetailsData.priceArr">
                  
                  
                  <div class="row course-single-row" *ngIf="singleprice['is_live'] ===0">
                   
                    <div class="col-sm-2 col-2">
                      <input type="checkbox" formControlName="price" (change)="checkValue(singleprice['amount'],$event.target.checked,singleprice['id'])">
                    </div>
                    <div class="col-sm-7 col-7" style="line-height: 22px">
                      <p class="pt-2">
                        {{singleprice['name']}}
                      </p>
                      <!--<small><a href="#" class="text-info" data-toggle="modal"
                          data-target="#exampleModal"><em>Learn More</em></a></small>-->
                    </div>
                    <div class="col-sm-3 col-3 text-right">
                      ${{singleprice['amount']}}
                    </div>
                  </div>
                </li>
                

                <li>Total Price <span class="text-info"><i class="fas fa-dollar-sign"></i>{{finalPrice}} </span>
                </li>
                <li class="border-bottom-0">

                  <div class="form-group">
                    <label >Email Address</label>
                    <input type="text"   class="form-control" formControlName="email">
                    <div *ngIf="bookingForm.get('email')?.errors &&
                    bookingForm.get('email')?.hasError('email') && (bookingForm.touched || bookingForm.dirty)">
                      <span class="text-danger">Please enter a valid email</span> 
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Phone</label>
                    <input type="text" class="form-control" formControlName="phone">
                    <div *ngIf="bookingForm.get('phone')?.errors &&
                    bookingForm.get('phone')?.hasError('phone') && (bookingForm.touched || bookingForm.dirty)">
                      <span class="text-danger">Please enter your contact number</span> 
                    </div>
                  </div>
                  <div class="form-group">
                    <label>How you get us know</label>
                    <select class="form-control" formControlName="reference">
                      <option disabled="">Select</option>
                      <option value="1">Friend’s Reference </option>
                      <option value="2">Social Media </option>
                      <option value="3">Google Search</option>
                      <option value="4">Email</option>
                    </select>
                    <div *ngIf="bookingForm.get('reference')?.errors &&
                    bookingForm.get('reference')?.hasError('reference') 
                    && (bookingForm.touched || bookingForm.dirty)">
                      <span class="text-danger">Please enter How you get us know</span> 
                    </div>
                  </div>
                  <a class="" target="_blank" routerLink="/refund"> See our Refund Policies</a><br/>
                  <div class="form-group">
                    <div *ngIf="errorMessage">
                      <p class="text-danger">{{errorMessage}}</p> 
                    </div>
                  </div>
                  <!--<a href="cart.html" class="btn-small btn-block text-center mt-2">Book Now</a>-->
                  <button class="btn-small btn-block text-center mt-2" disabled="{{buttonDisabled}}" type="submit">Book Now</button>
                  <div class="text-center" >
                    <img  *ngIf="processing" src="assets/img/loading.gif" /> 
                  </div>
                </li>

              </ul>
              </form>

            </div>

            <div class="footer-social-link d-none">

              <h2>Share via</h2>

              <ul>

                <li><a href="#"> <i class="fab fa-facebook-f"></i> </a></li>

                <li><a href="#"> <i class="fab fa-instagram"></i> </a></li>

                <li><a href="#"> <i class="fab fa-twitter"></i> </a></li>

                <li><a href="#"> <i class="fab fa-google-plus-g"></i> </a></li>

              </ul>

            </div>



          </div>

        </div>

      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>