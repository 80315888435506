import { Component, OnInit,Injectable,PLATFORM_ID,Inject,Renderer2  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';
import { ActivatedRoute,Router } from '@angular/router';
import { ReactiveFormsModule,FormBuilder,FormGroup,FormControl,Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import * as myGlobals from '../../../globals';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})

export class PaymentComponent implements OnInit {
  
  private stripeInvoiceFlag: number = myGlobals.isStripeInvoiceShown;
  private payCurrency: string = myGlobals.paymentCurrency;
  private paypalClientId: string =  myGlobals.paypalClientId;
  public webinarDetailsData = [];
  public paymentItemArr = [];
  public currentDate = new Date();
  public webinarDate;
  //public userForm;
  public userInvoiceForm;
  public cartId;
  public cartData = [];
  public finalPrice;
  public errorMessage;
  public successMessage;
  public errorMessageInvoice;
  public successMessageInvoice;
  public priceDetails = [];
  private orderId;
  private buyFree = 0;
  public paymentDoneFlag = 0;
  private buttonDisabled;
  public monthRange = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  public yearRange = ['2024','2025','2026','2027','2028','2029','2030','2031','2032',
  '2033','2034','2035','2036','2037','2038','2039','2040'];
  public countryRange ={'US':'United States','GB':'United Kingdom','CA':'Canada','DE':'Germany'};
  public isStripeShown: boolean = false ;
  public payPalConfig?: IPayPalConfig;

  public userForm: FormGroup = new FormGroup({
    cname: new FormControl(''),
    cardNumber: new FormControl(''),
    expMonth: new FormControl(''),
    expYear: new FormControl(''),
    cvc: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    country: new FormControl(''),
    city: new FormControl(''),
    zip: new FormControl(''),
  });

  constructor(private cgapiService: WebinarapiService ,
   private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2, ) { }
    public loading:boolean = false;
    public processing:boolean = false;
    public processingInvoice:boolean = false;
    
  ngOnInit() {
    this.loadScript();
    this.loading = false;
    //let id = this.route.snapshot.paramMap.get('id');
    //let id = this.cookieService.get('webinar_ses');

    this.cartId = this.cookieService.get('webinar_ses');
    if (isPlatformBrowser(this.platformId)) {
      if(this.cartId != '') {
        this.cgapiService.getCart(this.cartId)
        .subscribe( 
          data => {
            if(data.result != undefined)
            {
              this.createPaymentItemArr(data.result['cart']);
              const mappedArr = Object.keys(data.result['cart']).map(
                key => (data.result['cart'][key])
                );
              this.cartData['result'] = mappedArr;

              this.cartData['totalAmount'] = data.result['totalAmount'];
              this.finalPrice = this.cartData['totalAmount'];

              
            }

          }
          
          );
        }
    
    this.loading = false;
    //this.orderId = id;

      this.formBuilder = new FormBuilder();
      this.userForm = this.formBuilder.group({
          cname: [null, [Validators.required]],
         cardNumber: [null, [Validators.required]],
         expMonth: [null, [Validators.required]],
         expYear: [null, [Validators.required]],
         cvc: [null, [Validators.required]],
         address1: [null, [Validators.required]],
         address2: [null, []],
         country: [null, [Validators.required]],
         city: [null, [Validators.required]],
         zip: [null, [Validators.required]]
       });
      

      /* this.userForm = this.formBuilder.group({

         cname: ['', [Validators.required]],
         cardNumber: ['', [Validators.required]],
         expMonth: ['', [Validators.required]],
         expYear: ['', [Validators.required]],
         cvc: ['', [Validators.required]],
         
       });
       */
       this.formBuilder = new FormBuilder();
       this.userInvoiceForm = this.formBuilder.group({
        invoicename: [null, [Validators.required]],
      });


      this.initConfig();
    }
  }

  onDirectSubmit(){
    if (isPlatformBrowser(this.platformId)) {
      if(this.userInvoiceForm.valid){
        this.processingInvoice = true;

        var dataset =
        {
          'cartId':this.cartId,
          //'cname': this.userInvoiceForm.value.invoicename,
          'cname': this.userInvoiceForm.get('invoicename'),
          'totalPrice': this.finalPrice,
        }

        this.cgapiService.postDirectInvoice(dataset)
        .subscribe(data => {
              if(data['result']['paylink'] != undefined)
              {
                this.buttonDisabled = false;
                this.processing = false;
                this.processingInvoice = false;

                this.errorMessageInvoice ='Stripe invoice has been created for order #'+this.cartId+'. Our team will get back to you shortly.';
                this.successMessageInvoice ='Stripe invoice has been created for order #'+this.cartId+'. Our team will get back to you shortly.';
                
                //this.paymentDoneFlag = 1;
                this.cookieService.set('webinar_ses','');
                this.cgapiService.setDomainCartData(0);
              }
            })
      } else {
        this.errorMessageInvoice ='Please enter a valid name';
      }
    }
  }

  onSubmit(){
    if (isPlatformBrowser(this.platformId)) {
      if(this.finalPrice < 1)
      {
        this.errorMessage = 'Please add some item'
      }
      else if(this.userForm.valid){
        this.buttonDisabled = false;
        this.processing = true;
        //Order Post Preparation
        /*var dataset =[];
        dataset['id'] = this.webinarId;
        dataset['totalPrice'] = this.finalPrice;
        dataset['totalPriceArr'] =this.priceDetails;
        dataset['email'] =this.userForm.value.email;
        dataset['contact'] =this.userForm.value.phone;
        */
      var expiryYear = this.userForm.value.expYear;
      var dataset =
        {
          'cartId':this.cartId,
          'totalPrice': this.finalPrice,
          'cname': this.userForm.value.cname,
          'cardNumber':this.userForm.value.cardNumber,
          'expMonth':this.userForm.value.expMonth,
          'expYear':expiryYear,
          'cvc':this.userForm.value.cvc,
          'address1':this.userForm.value.address1,
          'address2':this.userForm.value.address2,
          'city':this.userForm.value.city,
          'country':this.userForm.value.country,
          'zip':this.userForm.value.zip,
        };
        console.log(dataset)
        
      /*var expiryYear = this.userForm.controls['expYear'].value;
      var dataset =
        {
          'cartId':this.cartId,
          'totalPrice': this.finalPrice,
          'cname': this.userForm.get('cname'),
          'cardNumber':this.userForm.get('cardNumber'),
          'expMonth': this.userForm.get('expMonth'),
          'expYear': expiryYear,
          'cvc':this.userForm.get('cvc'),
          'address1': this.userForm.get('address1'),
          'address2':this.userForm.get('address1'),
          'city': this.userForm.get('city'),
          'country':this.userForm.get('country'),
          'zip':this.userForm.get('zip'),
        };
        */
      /*
      var dataset =
        {
          'cartId':this.cartId,
          'totalPrice': this.finalPrice,
          'cname': this.userForm.value.cname,
          'cardNumber':this.userForm.value.cardNumber,
          'expMonth':this.userForm.value.expMonth,
          'expYear':expiryYear,
          'cvc':this.userForm.value.cvc 
        };
        */
        this.cgapiService.postPayment(dataset)
        .subscribe(data => {
          //Data Preparation

              if(data['0'].result['paySuccess'] != undefined  && data['0'].result['paySuccess'] != '')
              {
                this.buttonDisabled = false;
                this.processing = false;
                this.errorMessage ='Payment has been successfull for order #'+data["0"].result['orderId']+'. Our team will get back to you shortly.';
                this.successMessage ='Payment has been successfull for order #'+data["0"].result['orderId']+'. Our team will get back to you shortly.';
                this.paymentDoneFlag = 1;
                this.successMessageInvoice = '';
                this.errorMessageInvoice = '';
                this.cookieService.set('webinar_ses','');
                this.cgapiService.setDomainCartData(0);
              }
              else
              {
                this.buttonDisabled = true;
                this.processing = false;
                var errormsg = data['0'].result['error']['payErrorMessage'];
                

                if(errormsg == '')
                {
                  this.errorMessage =' Payment has been failed. Please try again with valid card details.';
                }
                else {
                  this.errorMessage = errormsg+' Payment has been failed. Please try again.';
                }
              }
            
        }); 
      
      
    } else {

      this.errorMessageInvoice ='Please enter valid card details.';
      this.errorMessage ='Please enter valid card and address details';
    }
  }
 }

  

 checkValue(price:number, isChecked: boolean,checkId:number) {
   if(isChecked) 
   {
    this.priceDetails.push(checkId);
    this.finalPrice = this.finalPrice + price ;
   }
   else
   {
    var arrindex = this.priceDetails.indexOf(checkId);
    if (arrindex > -1) {
      this.priceDetails.splice(arrindex, 1);
    }
    this.finalPrice = this.finalPrice - price ;
   }
   
}

toggleShow() {

   this.isStripeShown = ! this.isStripeShown;
   
   }

trimCard() {
    if (isPlatformBrowser(this.platformId)) {
        //this.userForm.value.cardNumber = this.userForm.value.cardNumber.trim();
        //this.userForm.controls['cardNumber'].value = this.userForm.controls['cardNumber'].value.trim();
    }
    
  }

  createPaymentItemArr(cartItemArr) {

    for (var i=0; i<cartItemArr.length; i++) {
        //console.log(cartItemArr[i]['item_details']);

        let priceArr = cartItemArr[i]['pricelist'];
        let singlePriceArr = [];

        for (var j=0; j<priceArr.length; j++) {
          if(priceArr[j]['selected'] ==1) {
            var titleText = cartItemArr[i]['item_details']['title'].replace(/[^a-zA-Z0-9 ]/g, '');
            
            this.paymentItemArr.push({
              name: titleText.substring(0,30),
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: this.payCurrency,
                value: priceArr[j]['amount'],
              }
            })
          }
          
        }
      }
    
    
    }

    private initConfig(): void {
     // this.createPaymentItemArr();

      this.payPalConfig = {
      currency: this.payCurrency,
      clientId: this.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        
        purchase_units: [
          {
            reference_id:this.cartId,
            custom_id:this.cartId,
            invoice_id:this.cartId,
            amount: {
              currency_code: this.payCurrency,
              value: this.finalPrice,
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: this.finalPrice
                }
              }
            },
            items: this.paymentItemArr
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        //console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          //console.log('onApprove - you can get full order details inside onApprove: ', details);

          if(details.status == 'APPROVED') {
            //API Call
            this.paymentSuccessNotify(details);
          }
        });
      },
      onClientAuthorization: (data) => {
        //console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        //console.log('OnCancel', data, actions);

        this.paymentFailureNotify('OnCancel',data,actions);
      },
      onError: err => {
        //console.log('OnError', err);
        this.paymentFailureNotify('error',err);
      },
      onClick: (data, actions) => {
        //console.log('onClick', data, actions);
      },
    };
    }
  
    paymentSuccessNotify(details) {
      //API Call
      var dataset =
      {
         'cartId':this.cartId,
         'totalPrice': this.finalPrice,
         'paymentDetails':details
      }
      this.cgapiService.postPaypalPayment(dataset)
      .subscribe(data => {})
      this.errorMessage ='Payment has been successfull for order #'+this.cartId+'. Our team will get back to you shortly.';
      this.successMessage ='Payment has been successfull for order #'+this.cartId+'. Our team will get back to you shortly.';
      this.paymentDoneFlag = 1;
      this.successMessageInvoice = '';
      this.errorMessageInvoice = '';
      this.cookieService.set('webinar_ses','');
      this.cgapiService.setDomainCartData(0);
    }

    paymentFailureNotify(details,data,action='') {
      var paymentError = 'Payment failed';
      if(details == 'OnCancel') {
        paymentError = 'Payment cancelled by customer on payment page';
      }
      
      var paymentDetails = [{'details':details,'data':data,'action':action,'paymentError':paymentError}]
      var dataset =
      {
         'cartId':this.cartId,
         'totalPrice': this.finalPrice,
         'paymentDetails':paymentDetails
      }
      //API Call
      this.cgapiService.postFailPaypalPayment(dataset)
      .subscribe(data => {})
      this.errorMessage ='Payment has been failed for order #'+this.cartId+'. Our team will get back to you shortly.';
      
    }

    public loadScript() {
      let inscript = this.renderer2.createElement('script');
        inscript.type = `application/ld+json`;
        inscript.innerHTML = '';
        inscript.src = 'https://www.paypal.com/sdk/js?client-id=AVI0VVEvIdlrIUatXGh3pfL6PJUUP0rwCfmVZhOSTrSuONQmD97y07ppItEZkCPXQOdXCuePnwdmodZY';
        inscript.async = true;
        inscript.defer = true;

        this.renderer2.appendChild(this.document.body, inscript);
    }

    public loadScriptOld() {
        let body = <HTMLDivElement> document.body;
        let script = document.createElement('script');
        script.innerHTML = '';
        script.src = 'https://www.paypal.com/sdk/js?client-id=AVI0VVEvIdlrIUatXGh3pfL6PJUUP0rwCfmVZhOSTrSuONQmD97y07ppItEZkCPXQOdXCuePnwdmodZY';
        script.async = true;
        script.defer = true;
        body.appendChild(script);
    }
}

