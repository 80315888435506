//
// ===== File globals.ts    
//
'use strict';


export const apiURL='https://complynext.com/v2/';
//export const apiURL='https://edphonic.org/v2/';
//export const apiURL='http://localhost/webinar-api/public/v2/';
export const isStripeShown = true;
export const isStripeInvoiceShown = 0;
export const isPaypalShown = 1;
export const paymentCurrency = 'USD';
//info@sellyco.com
//export const paypalClientId = 'AWzok5cWmu-HJubDYEBLACAGaWybtxEgisqrUh_X8953wnRp2IMpUQUXnfRgw-sB0uvuM4bUfFPBVtK_';
//sunitha.nithin563@gmail.com
//export const paypalClientId = 'AU0E6EMUcD8SzPcli4K1zvnbAJEWO5fLPKSNQ02n-U9tzQVGGwL5XUUqqGdJV63sIvjoCl9PEx4to-Vb';
//nithiyananthanm563@gmail.com
export const paypalClientId = 'ARMntr-ZkiEMVa5Z1wUkoSadsU5GsEk9twe8LogE3Nyo-taYtvirfYL7xhJFF0rgSV3KSLJ74sM6Tg82'
//ng build --configuration production --aot