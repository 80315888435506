<app-authheader></app-authheader>

<div class="page-banner">
    <div class="hvrbox">
        <img src=" assets/imgs/online-training.jpg" alt="Mountains" class="hvrbox-layer_bottom">
        <div class="hvrbox-layer_top">
            <div class="container">
                <div class="overlay-text text-center">
                    <h2 class="font-weight-bold">Dashboard
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="course-header-1x">
    <div class="container">
        <div class="course-details-1x">

            <div class="row">
                <div class="col-md-4">
                
                </div>
            
                <div class="col-md-12">
                    <form action="#">
                        <div class="table-content table-responsive cart-table-content header-color-gray">
                            <table>
                                <thead>
                                    <tr class="bg-gray">
                                        <th class="product-name">Product</th>
                                        <th class="product-price"> Price</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name"><a href="course-details.html">Writing Effective
                                                sops for FDA and CLIA
                                                Regulated Companies</a></td>

                                        <td class="product-price"><span class="amount">$ 149.00</span></td>

                                        <td class="cart-quality">
                                            <div class="input-group">
                                                <input type="button" value="-" class="button-minus"
                                                    data-field="quantity">
                                                <input type="number" step="0" max="" value="1" name="quantity"
                                                    class="quantity-field">
                                                <input type="button" value="+" class="button-plus"
                                                    data-field="quantity">
                                            </div>
                                        </td>
                                        <td class="price-total">
                                            <span class="amount">$ 149.00</span>
                                        </td>
                                        <td class="product-remove">
                                            <a href="#"><i class="far fa-times-circle"></i></a>
                                        </td>
                                    </tr>
                               
                                </tbody>
                            </table>
                        </div>
                    </form>
                    

                </div>

           
            </div>

        </div>
    </div>
</div>

<app-footer></app-footer>
