import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.css']
})
export class IndustriesComponent implements OnInit {

  public homeIndustriesListData = [];
  constructor(private cgapiService: WebinarapiService,
    @Inject(PLATFORM_ID) private platformId: Object ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getIndustriesList()
        .subscribe( data => this.homeIndustriesListData = data['0']); 
    }
  }

}
