<app-header></app-header>
<app-banner></app-banner>
<div id="courses" class="section" >

  <!-- container -->
  <div class="container">

     <!-- courses -->
     <div id="courses-wrapper">
        <div class="col-md-12 col-sm-12 text-center" >
            <img  *ngIf="loading" src="assets/img/loading.gif" /> 
          </div>
      <!-- row -->
      <h4 *ngIf="noResult">No results were found for the search for <strong class="text-danger">{{searchKey}}</strong></h4>
      <div class="row" *ngIf="webinarListData" >
        <h4 *ngIf="webinarListData.result && !noResult">{{ webinarListData.result.length }} results were found for the search for <strong class="text-danger">{{searchKey}}</strong></h4>
        
        <!-- single course -->
        <div class="col-md-4 col-sm-12 col-xs-6" 
         *ngFor="let singleWebinar of webinarListData.result">
             <div class="course" style="background-color: #E9E9E9; padding: 2px 3px;">
            <div class="row">
            <div class="col-md-7 col-sm-12 col-xs-7"><small>{{ singleWebinar.category_name }}</small></div>
            <div class="col-md-5 col-sm-12 col-xs-5" ><small>{{ singleWebinar.webinar_type_txt }}</small></div>
            </div>
            <a class="course-title" style="font-size: 15px;" [routerLink]="['/webinar/', singleWebinar.webinar_id]" 
            title="{{ singleWebinar.title }}">
            {{ singleWebinar.title?.length>120 ? (singleWebinar.title | slice:0:120)+'..' : (singleWebinar.title) }}</a>
            <div class="course-details">
                <div class="row">
                    <div class="col-md-8 col-sm-12 col-xs-8">
                        <span *ngIf="singleWebinar.upcoming_flag">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <i class="fa fa-calendar"></i>{{ singleWebinar.start_date|date }}
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12" >
                                    <i class="fa fa-credit-card"></i>&nbsp;&nbsp;<span> {{ singleWebinar.price|currency }}</span>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="!singleWebinar.upcoming_flag">
                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                   <small>Duration: {{ singleWebinar.duration }}</small> 
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12" >
                                    <i class="fa fa-credit-card"></i> <span> {{ singleWebinar.cd_price|currency }}</span>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="col-md-4 col-sm-12 col-xs-4">
                        <a class="btn btn-success"  [routerLink]="['/webinar/', singleWebinar.webinar_id]" 
                        title="{{ singleWebinar.title }}">Book Now</a>
                    </div>
                
                
                
            </div>
            
          </div>
        </div>
        <!-- /single course -->



      </div>
      <!-- /row -->

      
      
    </div>
    <!-- /courses -->

   

  </div>
  <!-- container -->

</div>
<app-footer></app-footer>