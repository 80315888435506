import { Component, OnInit,Injectable,PLATFORM_ID,Inject  } from '@angular/core';
import { DOCUMENT,PlatformLocation,isPlatformBrowser, isPlatformServer  } from '@angular/common';
import { WebinarapiService } from '../../../services/webinarapi.Service';

@Component({
  selector: 'app-industrydetails',
  templateUrl: './industrydetails.component.html',
  styleUrls: ['./industrydetails.component.css']
})
export class IndustrydetailsComponent implements OnInit {
  public domainListData = [];
  constructor(private cgapiService: WebinarapiService,
    @Inject(PLATFORM_ID) private platformId: Object ) { }
  
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cgapiService.getDomainDetails()
        .subscribe( data => this.domainListData = data['0']); 
    }
  }

}
